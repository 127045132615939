import { Form } from "antd";
import { translate } from "../../../core";
import { NumericInput } from "../../../core/component";
import { InputCard } from "./InputCard";
import styles from "./ItemEntry.module.scss";

export const ItemFormQuantity = () => {
  return (
    <InputCard className="py-0 mb-0" title={translate("quantity")}>
      <table className={["table table-borderless", styles["QuantityTable"]].join(" ")}>
        <body>
          <tr>
            <td className={styles.QuantityCaption}>{translate("on_hand")}</td>
            <td>
              <Form.Item noStyle name={["quantity", "onHand"]}>
                <NumericInput
                  style={{ width: 70 }}
                  decimalPlaces={0}
                />
              </Form.Item>
            </td>
            <td className={styles.QuantityCaption}>{translate("display")}</td>
            <td>
              <Form.Item noStyle name={["quantity", "display"]}>
                <NumericInput
                  style={{ width: 70 }}
                  decimalPlaces={0}
                />
              </Form.Item>
            </td>
          </tr>
          <tr>
            <td className={styles.QuantityCaption}>{translate("on_order")}</td>
            <td>
              <Form.Item noStyle name={["quantity", "onOrder"]}>
                <NumericInput
                  style={{ width: 70 }}
                  decimalPlaces={0}
                />
              </Form.Item>
            </td>
            <td className={styles.QuantityCaption}>{translate("back_order")}</td>
            <td>
              <Form.Item noStyle name={["quantity", "backOrder"]}>
                <NumericInput
                  style={{ width: 70 }}
                  decimalPlaces={0}
                />
              </Form.Item>
            </td>
          </tr>
          <tr>
            <td className={styles.QuantityCaption}>{translate("in_set")}</td>
            <td>
              <Form.Item noStyle name={["quantity", "inSet"]}>
                <NumericInput
                  style={{ width: 70 }}
                  decimalPlaces={0}
                />
              </Form.Item>
            </td>
            <td className={styles.QuantityCaption}>{translate("committed")}</td>
            <td>
              <Form.Item noStyle name={["quantity", "committed"]}>
                <NumericInput
                  style={{ width: 70 }}
                  decimalPlaces={0}
                />
              </Form.Item>
            </td>
          </tr>
          <tr>
            <td className={styles.QuantityCaption}>{translate("min")}</td>
            <td>
              <Form.Item noStyle name={["quantity", "min"]}>
                <NumericInput
                  style={{ width: 70 }}
                  decimalPlaces={0}
                />
              </Form.Item>
            </td>
            <td className={styles.QuantityCaption}>{translate("max")}</td>
            <td>
              <Form.Item noStyle name={["quantity", "max"]}>
                <NumericInput
                  style={{ width: 70 }}
                  decimalPlaces={0}
                />
              </Form.Item>
            </td>
          </tr>
        </body>
      </table>
    </InputCard>
  );
};

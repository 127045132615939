import { FC } from 'react'
import { Button, Space } from 'antd'
import { ShowIf } from '../../../core/component'
import { DeleteOutlined, EyeOutlined, SaveOutlined } from '@ant-design/icons'
import { CloseButton } from 'react-bootstrap'

export const ContactFormRelationsActions: FC<{
  value?: number,
  index: number,
  remove: () => void,
  removeLoading: boolean,
  save: () => void,
  saveLoading: boolean,
  cancel: () => void,
  view: () => void,
}> = ({value, remove, removeLoading, cancel, save, saveLoading, view}) => {
  return (
    <Space>
      <Button
        loading={saveLoading}
        type='text'
        shape='circle'
        icon={<SaveOutlined/>}
        onClick={save}
      />

      <ShowIf
        condition={!!value}
        replacement={
          <Button
            icon={<CloseButton/>}
            shape='circle'
            type='text'
            onClick={cancel}
          />
        }>

        <Button
          loading={removeLoading}
          danger
          icon={<DeleteOutlined/>}
          shape='circle'
          type='text'
          onClick={remove}
        />

        <Button
          icon={<EyeOutlined/>}
          shape='circle'
          type='text'
          onClick={view}
        />
      </ShowIf>
    </Space>
  )
}
import React, { useEffect, useMemo, useState } from 'react'
import { Container, ErrorMessage, Panel, SinglePageable, SinglePageableResponse } from '../../../core/component'
import { Button, Empty, Form } from 'antd'
import { confirm, notify, translate } from '../../../core'
import {
  CloseOutlined, CopyOutlined,
  DeleteOutlined, HistoryOutlined,
  PlusOutlined,
  SaveFilled,
  SearchOutlined,
  StopOutlined,
} from '@ant-design/icons'
import {
  BadRequestException,
  EntryMode,
  PageableRequest, PageContextValue, QueryPageableRequest, QueryPageableResponse,
} from '../../../core/type'
import { Item, ItemEntry } from '../type'
import { ItemForm } from './ItemForm'
import { ItemPageContext } from './ItemPageContext'
import { ItemSearch } from './ItemSearch'
import styles from './ItemPage.module.scss'
import { useOrderReport } from '../../order/hook/order-report.hook'
import { useLazyQuery } from '@apollo/client'
import { useItemData } from '../hook/item-data-hook'
import { GetItemPage, GetItemsForPage } from '../graphql/item.graphql'
import { ItemFindRequest, ItemPageRequest } from '../type/item-query'
import { ItemPagePrintButton } from './ItemPagePrintButton'

export const ItemPage = () => {
  const [sending, setSending] = useState<boolean>(false)
  const [entity, setEntity] = useState<Item>()
  const [page, setPage] = useState<number | null>()
  const [errors, setErrors] = useState<string[]>([])
  const [searchOpen, setSearchOpen] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [entryMode, setEntryMode] = useState<EntryMode>(EntryMode.Nothing)
  const {report: orderReport} = useOrderReport()
  const [sendingDuplicate, setSendingDuplicate] = useState<boolean>(false)
  const {init, toDto, create, update, remove, duplicate} = useItemData()
  const [find, {loading}] = useLazyQuery<QueryPageableResponse<Item, 'itemsFind'>,
    QueryPageableRequest<ItemFindRequest>>(GetItemsForPage)
  const [findRequest, setFindRequest] = useState<ItemFindRequest>()
  const [findPage] = useLazyQuery<{ itemPage: number }, { request: ItemPageRequest }>(GetItemPage)
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false)

  useEffect(() => {
    setIsFormChanged(false)
  }, [entity])

  const onEntityChange = async (entity: Item | null, mode: EntryMode | null = null) => {
    if (entity) {
      setEntryMode(EntryMode.Modify)
    } else {
      if (mode === EntryMode.New) {
        form.resetFields()
        setEntryMode(EntryMode.New)
        entity = await init()
      }
    }

    setEntity(entity || undefined)
  }

  const save = async (entry: ItemEntry) => {
    setErrors([])
    const id = entity?.id

    setSending(true)

    const dto = toDto(entry)

    try {
      if (id) await update(id, dto)
      else {
        const result = await create(dto)
        if (entity) entity.id = result.id
      }

      notify.success(
        translate('item'),
        translate('save_success_message'),
      )

      await refresh()
    } catch (e) {
      if (e instanceof BadRequestException) setErrors(e.message)
    } finally {
      setSending(false)
    }
  }

  const handleDuplicate = async () => {
    if (!entity)
      return

    const result = await confirm(
      translate('copy_item_confirmation'),
    )

    if (!result) return

    setSendingDuplicate(true)

    try {
      const result = await duplicate(entity?.id)
      const {data: pageResponse} = await findPage({
        variables: {
          request: {
            id: result?.id,
          },
        },
      })
      return fetch({}, pageResponse?.itemPage)
    } catch (e) {
      if (e instanceof BadRequestException) setErrors(e.message)
    } finally {
      setSendingDuplicate(false)
    }
  }

  const handleRemove = async () => {
    if (!entity) return

    const result = await confirm(
      translate('remove_confirmation_message', 'item', '?'),
    )

    if (!result) return

    await remove(entity.id)
    close()

    notify.success(
      translate('remove', 'item'),
      translate('the', 'item', 'remove_success_message'),
    )
  }

  const onChange = async (
    request: PageableRequest,
    page: number,
  ): Promise<SinglePageableResponse<Item>> => {

    const {data: result} = await find({
      variables: {
        request: {
          ...request,
          ...findRequest,
        },
      },
    })

    const [entity] = result?.itemsFind.data || []
    await onEntityChange(entity)

    if (!page) setPage(1)

    return {
      count: result?.itemsFind.count || 0,
    }
  }

  const fetch = (request: ItemFindRequest, page?: number) => {
    setFindRequest(request)
    changePage(0)
    setTimeout(() => {
      changePage(page)
    }, 500)
  }

  const changePage = (page: number = 1) => {
    setPage(0)
    setTimeout(() => setPage(page), 500)
  }

  const close = () => {
    setEntity(undefined)
    setEntryMode(EntryMode.Nothing)
  }

  const refresh = async () => {
    const {data: result} = await find({
      variables: {
        request: {
          id: entity?.id,
          take: 1,
          skip: 0,
        },
      },
    })
    const [item] = result?.itemsFind.data || []
    await onEntityChange(item)
  }

  const contextValue = useMemo<PageContextValue<Item, ItemEntry>>(() => ({
    form, save, entity, refresh, fetch: fetch, onFormChange: () => setIsFormChanged(true),
  }), [form, save, entity, refresh, fetch])

  return (
    <ItemPageContext.Provider value={contextValue}>
      <Container
        leftClassName="action-container"
        paddingClass="0"
        loading={loading}
        toolbarItems={{
          start: [
            <Button
              key={translate('search')}
              onClick={() => setSearchOpen(true)}
              icon={<SearchOutlined/>}
            >
              {translate('search')}
            </Button>,

            <Button
              onClick={() => onEntityChange(null, EntryMode.New)}
              icon={<PlusOutlined/>}
              key={translate('add')}
            >
              {translate('add')}
            </Button>,

            <Button
              loading={sending}
              onClick={() => form.submit()}
              disabled={!entity}
              type={isFormChanged ? 'primary' : 'default'}
              icon={<SaveFilled/>}
              key={translate('save')}
            >
              {translate('save')}
            </Button>,

            <Button
              danger
              disabled={!entity?.id}
              onClick={handleRemove}
              icon={<DeleteOutlined/>}
              key={translate('remove')}
            >
              {translate('remove')}
            </Button>,

            <Button
              disabled={entryMode === EntryMode.Nothing}
              onClick={async () => {
                if (entryMode === EntryMode.New) {
                  form.resetFields()
                  setIsFormChanged(false)
                }
                if (entryMode === EntryMode.Modify)
                  await refresh()
              }}
              icon={<StopOutlined/>}
              key={translate('clear')}
            >
              {translate('clear')}
            </Button>,

            <Button
              loading={sendingDuplicate}
              disabled={entryMode === EntryMode.Nothing}
              onClick={handleDuplicate}
              icon={<CopyOutlined/>}
              key={translate('copy')}
            >
              {translate('copy')}
            </Button>,

            <Button
              key={translate('history')}
              disabled={!entity?.id}
              onClick={() => entity?.id && orderReport.itemHistory(entity?.id)}
              icon={<HistoryOutlined/>}
            >
              {translate('history')}
            </Button>,

            <ItemPagePrintButton
              item={entity}
            />,

            <Button
              key={translate('exit')}
              onClick={close}
              icon={<CloseOutlined/>}
            >
              {translate('exit')}
            </Button>,
          ],
          end: [<SinglePageable key={'pagination'} onChange={onChange} defaultPage={page}/>],
        }}
      >
        <ItemSearch
          open={searchOpen}
          closeMe={() => setSearchOpen(false)}
        />

        {entity ? (
          <div className="p-1 mt-2 bg-gray">
            <ErrorMessage title={translate('entry_fail_message', 'inventory')} message={errors}/>

            <Panel className={styles['form-panel']}>
              <ItemForm/>
            </Panel>
          </div>
        ) : (
          <Empty className="mt-5"/>
        )}
      </Container>
    </ItemPageContext.Provider>
  )
}

import { useContext } from 'react'
import { useLazyQuery } from '@apollo/client'
import { translate } from '../../../core'
import { PageContextValue } from '../../../core/type'
import { AppraisalPageContext } from './AppraisalPageContext'
import { AppraisalFindRequest } from '../type'
import { GetAppraisalPage } from '../graphql/appraisal.graphql'
import { SearchModal, SearchProps } from '../../../core/component/Search'

export const AppraisalSearch = ({open, closeMe}: SearchProps) => {
  const {fetch} = useContext<PageContextValue<any, any>>(AppraisalPageContext)
  const [findPage, {loading}] = useLazyQuery<{ appraisalPage: number }, { number: string }>(
    GetAppraisalPage,
    {errorPolicy: 'all'}
  )
  return (
    <SearchModal<AppraisalFindRequest>
      open={open}
      closeMe={closeMe}
      onComplete={(request, page) => {
        fetch!(request, page)
        closeMe()
      }}
      title={translate('search', 'appraisal')}
      idLabel={translate('appraisal', 'number_abbr')}
      searchOptions={[
        {
          key: 'customerContactId',
          label: translate('contact_id'),
          setValue: (params, request) => {
            request.customerContactId = params.value
          },
          hideExactMatch: true,
        },
        {
          key: 'customerLastName',
          label: translate('customer', 'last_name'),
          setValue: (params, request) => {
            request.customerLastName = params.value
          },
          hideExactMatch: true,
        },
        {
          key: 'customerFirstName',
          label: translate('customer', 'first_name'),
          setValue: (params, request) => {
            request.customerFirstName = params.value
          },
          hideExactMatch: true,
        },
        {
          key: 'customerCity',
          label: translate('customer', 'city'),
          setValue: (params, request) => {
            request.customerCity = params.value
          },
          hideExactMatch: true,
        }
      ]}
      findPage={async id => {
        const {data, error} = await findPage({
          variables: {
            number: id.toString(),
          },
        })

        return {
          page: data?.appraisalPage ?? 0,
          error: error?.graphQLErrors,
        }
      }}
      findPageLoading={loading}
    />
  )
}


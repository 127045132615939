import React, { useEffect } from 'react'
import { AutoComplete, Container } from '../../../../core/component'
import { Button, Checkbox, Form, Space } from 'antd'
import { translate } from '../../../../core'
import { InputCard } from '../../../inventory/component/InputCard'
import { StoneInventoryManagementArgs } from '../type/stone-inventory-management'
import { useItemReport } from '../../../inventory/hook/item-report.hook'
import { gql, useQuery } from '@apollo/client'

export const StoneInventoryManagement = () => {
  const [form] = Form.useForm<StoneInventoryManagementArgs>()
  const {report: {stoneInventoryManagement}} = useItemReport()
  const {data} = useQuery<{ itemStoneNames: string[] }>(gql`
      query GetItemStoneNames {
          itemStoneNames
      }
  `)

  const handleSubmit = async (args: StoneInventoryManagementArgs) => {
    return stoneInventoryManagement(args)
  }

  useEffect(() => {
    form.setFieldsValue({
      inStock: true
    })
  }, [])

  return (
    <>
      <div className='print-hidden'>
        <Container
          leftClassName='action-container'
          paddingClass='0'
        >
          <InputCard className='content-size'>
            <Form
              layout='vertical'
              form={form}
              onFinish={handleSubmit}
              className='d-flex flex-column gap-2'
            >
              <Space className='w-100 ' size='middle'>
                <label style={{width: 100}}>{translate('stone')}</label>
                <Form.Item
                  className='mb-1'
                  label={translate('from')}
                  name='stoneNameFrom'
                >
                  <AutoComplete style={{width: 192}} items={data?.itemStoneNames ?? []}/>
                </Form.Item>
                <Form.Item
                  className='mb-1'
                  label={translate('to')}
                  name='stoneNameTo'
                >
                  <AutoComplete style={{width: 192}} items={data?.itemStoneNames ?? []}/>
                </Form.Item>
              </Space>

              <Form.Item name='inStock' valuePropName='checked'>
                <Checkbox>{translate('in_stock_only')}</Checkbox>
              </Form.Item>

              <Space className='gap-1'>
                <Button
                  onClick={() => form.resetFields()}
                >
                  {translate('clear')}
                </Button>
                <Button
                  type='primary'
                  onClick={form.submit}
                >
                  {translate('print')}
                </Button>
              </Space>
            </Form>
          </InputCard>
        </Container>
      </div>
    </>
  )
}

import { gql, TypedDocumentNode } from '@apollo/client'
import { QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { ItemCategory, ItemCategoryFindRequest } from '../type'

export const GetItemCategories: TypedDocumentNode<
QueryPageableResponse<ItemCategory, 'itemCategoriesFind'>,
QueryPageableRequest<ItemCategoryFindRequest>> = gql`
    query GetItemCategoriesFind($request: ItemCategoryFindRequest!) {
        itemCategoriesFind(request: $request) {
            data {
                id
                title
                level
            }
            count
        }
    }
`

export const GetItemCategoriesForLookup = gql`
    query GetItemCategoriesFindLookup($request: ItemCategoryFindRequest!) {
        itemCategoriesFind(request: $request) {
            data {
                id
                title
                level
                parent {
                    id
                }
            }
        }
    }

`
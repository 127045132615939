import { DataGridColumn } from '../../../core/component'
import { translate } from '../../../core'

type DataGridColumnIndexable = DataGridColumn<any> & {
  index: number
}

export const jobGridColumnsFactory = <TGridItem extends object>({columns, name}: {
  columns: DataGridColumn<TGridItem>[],
  name: string
}): DataGridColumn<TGridItem>[] => {
  const customColumns = queueCustomColumns[name]
  let result: DataGridColumn<TGridItem>[] = []

  columns.forEach((column, index) => {
    const customs = customColumns.filter(cc => cc.index === index)
    result = [
      ...result,
      ...customs,
      column,
    ]
  })

  return result
}


const queueCustomColumns: Record<string, DataGridColumnIndexable[]> = {
  'item': [
    {
      dataIndex: 'input',
      title: translate('sku'),
      width: 200,
      render: data => data.sku,
      index: 1,
    }
  ],
  'order': [
    {
      dataIndex: 'input',
      title: translate('topic'),
      width: 200,
      render: data => data.topic,
      index: 1,
    }
  ],
  'wedding_registry': [
    {
      dataIndex: 'input',
      title: translate('number'),
      width: 200,
      render: data => data.number,
      index: 1,
    }
  ],
}

import { DataGrid, DataGridRef } from '../../../core/component'
import { confirm, notify, translate } from '../../../core'
import { WebhookEntry, WebhookStatus } from '../type/webhook'
import { useWebhookData } from '../hook/webhook-data.hook'
import { Button, Space, Tag, Tooltip } from 'antd'
import {
  InfoCircleOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined
} from '@ant-design/icons'
import { useRef, useState } from 'react'
import Enumerable from 'linq'
import { JsonViewer } from './JsonViewer'
import { toPascalCase } from '../../../core/until'

export const WebhookList = () => {
  const {get, activate, deactivate} = useWebhookData()
  const [sending, setSending] = useState<boolean>(false)
  const dataGridRef = useRef<DataGridRef<WebhookEntry>>(null)
  const [openJsonViewer, setOpenJsonViewer] = useState<boolean>(false)
  const [jsonContent, setJsonContent] = useState<any>()

  const handleToggleActivation = async (webhook: WebhookEntry) => {
    const result = await confirm(translate('sure_message'))

    if (!result)
      return

    setSending(true)

    try {
      webhook.status === WebhookStatus.Active
        ? await deactivate(webhook.id ?? 0)
        : await activate(webhook.id ?? 0)

      notify.success(
        '',
        translate('save_success_message'))

      dataGridRef.current?.refresh()
    } finally {
      setSending(false)
    }
  }

  const statusColorMapper: Record<WebhookStatus, string> = {
    [WebhookStatus.Active]: '#87d068',
    [WebhookStatus.Paused]: '',
    [WebhookStatus.Disabled]: '#f50',
  }


  return (
    <>
      <DataGrid<WebhookEntry>
        ref={dataGridRef}
        fetch={async () => {
          const data = await get()
          return {
            data: Enumerable.from(data.map(it => ({...it, key: it.id?.toString()})))
              .orderBy(it => it.id)
              .toArray() as WebhookEntry[],
            count: data.length,
          }
        }}
        columns={[
          {
            dataIndex: 'id',
            title: translate('id'),
            width: 200,
          },
          {
            dataIndex: 'topic',
            title: translate('topic'),
            width: 500,
          },
          {
            dataIndex: 'name',
            title: translate('name'),
            width: 500,
          },
          {
            dataIndex: 'status',
            title: translate('status'),
            width: 100,
            render: (value: WebhookStatus) => (
              <Tag color={statusColorMapper[value]}>{toPascalCase(value)}</Tag>
            )
          },
          {
            dataIndex: 'status',
            render: (text, record) => (
              <Space>
                <Tooltip title={translate(text === WebhookStatus.Active ? 'pause' : 'play')}>
                  <Button
                    loading={sending}
                    type='text'
                    shape='circle'
                    icon={text === WebhookStatus.Active
                      ? <PauseCircleOutlined style={{fontSize: 25}}/>
                      : <PlayCircleOutlined style={{fontSize: 25}}/>
                    }
                    onClick={() => handleToggleActivation(record)}
                  />
                </Tooltip>

                <Tooltip title={translate('details')}>
                  <Button
                    type='text'
                    shape='circle'
                    icon={<InfoCircleOutlined style={{fontSize: 25}}/>}
                    onClick={() => {
                      setOpenJsonViewer(true)
                      setJsonContent(record)
                    }}
                  />
                </Tooltip>
              </Space>
            )
          }
        ]}
      />

      <JsonViewer
        open={openJsonViewer}
        onClose={() => setOpenJsonViewer(false)}
        content={jsonContent}
      />
    </>
  )
}
import { PrinterList } from './PrinterList'
import { Section } from '../../../../core/component'

export const PrinterSettingsContent = () => {
  return (
    <Section noMargin>
     <PrinterList/>
    </Section>
  )
}

import {
  Combo,
  DataGrid,
  DataGridRef,
  enumToComboOptionTransformer,
  ShowIf
} from '../../../core/component'
import { translate } from '../../../core'
import React, { useRef, useState } from 'react'
import { Button, Space, Tag, Tooltip } from 'antd'
import { JsonViewer } from './JsonViewer'
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { dateDisplay, dateFormatAndTimeSlashed } from '../../../core/until'
import { JobGrid, JobMetaData, JobStatus } from '../type/job'
import { useJobData } from '../hook/job-data.hook'
import { jobGridColumnsFactory } from '../func/job-grid-columns-factory'

export type JobListProps = {
  meta: JobMetaData
}

export const JobList = ({meta}: JobListProps) => {
  const dataGridRef = useRef<DataGridRef<JobGrid>>(null)
  const [openJsonViewer, setOpenJsonViewer] = useState<boolean>(false)
  const [jsonContent, setJsonContent] = useState<any>()
  const {get, retry} = useJobData()
  const [filteredJobStatus, setFilteredJobStatus] = useState<JobStatus[]>([])
  const [sending, setSending] = useState<boolean>(false)

  // @ts-ignore
  const statusColorMapper: Record<JobStatus, string> = {
    [JobStatus.Completed]: '#87d068',
    [JobStatus.Failed]: '#f50',
  }

  const getEnumKey = (status: JobStatus) => {
    const item = Object.entries(JobStatus).find(([_, value]) => value === status)
    if (!item)
      return ''

    const [key] = item
    return key
  }

  return (
    <div>
      <Space>
        <Combo<any, JobStatus>
          placeholder={translate('status')}
          style={{width: 200}}
          className="mb-3"
          allowMultiple
          size="middle"
          options={enumToComboOptionTransformer(JobStatus)}
          onChange={value => {
            setFilteredJobStatus(value as JobStatus[])
            dataGridRef.current?.refresh()
          }}
        />
      </Space>

      <DataGrid<JobGrid>
        ref={dataGridRef}
        fetch={async request => {
          const {data, count} = await get({
            ...request,
            name: meta.name,
            status: filteredJobStatus,
          })
          return {
            data: data.map(it => ({...it, key: it.id?.toString()})),
            count,
          }
        }}
        columns={[
          ...jobGridColumnsFactory<JobGrid>({
            columns: [
              {
                dataIndex: 'id',
                title: translate('#'),
                width: 50,
              },
              {
                dataIndex: 'createdAt',
                title: translate('created_at'),
                width: 250,
                render: value => dateDisplay(value, dateFormatAndTimeSlashed),
              },
              {
                dataIndex: 'updatedAt',
                title: translate('updated_at'),
                width: 250,
                render: value => dateDisplay(value, dateFormatAndTimeSlashed),
              },
              {
                dataIndex: 'attemptsMade',
                title: translate('attempts_made'),
                width: 150,
              },
              {
                dataIndex: 'status',
                title: translate('status'),
                width: 100,
                render: (status: JobStatus) => (
                  <Tag color={statusColorMapper[status]}>{getEnumKey(status)}</Tag>
                ),
              },
            ],
            name: meta.alias,
          }),
          {
            dataIndex: 'status',
            render: (value: JobStatus, record: JobGrid) => (
              <Space>
                <Tooltip title={translate('input')}>
                  <Button
                    type="text"
                    shape="circle"
                    icon={<InfoCircleOutlined style={{fontSize: 20}}/>}
                    onClick={() => {
                      setJsonContent(record.input)
                      setOpenJsonViewer(true)
                    }}/>
                </Tooltip>

                <ShowIf condition={value === JobStatus.Failed} replacement={<></>}>
                  <Tooltip title={translate(getEnumKey(record.status), 'data')}>
                    <Button
                      type="text"
                      shape="circle"
                      icon={<CloseCircleOutlined style={{fontSize: 20, color: statusColorMapper.Failed}}/>}
                      onClick={() => {
                        setJsonContent(record.failedReason)
                        setOpenJsonViewer(true)
                      }}/>
                  </Tooltip>
                </ShowIf>

                <ShowIf condition={value === JobStatus.Completed && record.returnValue} replacement={<></>}>
                  <Tooltip title={translate(getEnumKey(record.status), 'result')}>
                    <Button
                      type="text"
                      shape="circle"
                      icon={<CheckCircleOutlined style={{fontSize: 20, color: statusColorMapper.Completed}}/>}
                      onClick={() => {
                        setJsonContent(record.returnValue)
                        setOpenJsonViewer(true)
                      }}/>
                  </Tooltip>
                </ShowIf>

                <ShowIf condition={value === JobStatus.Failed} replacement={<></>}>
                  <Tooltip title={translate('retry')}>
                    <Button
                      disabled={sending}
                      type="text"
                      shape="circle"
                      icon={<PlayCircleOutlined style={{fontSize: 20, color: '#2F54EB'}}/>}
                      onClick={async () => {
                        setSending(true)

                        try {
                          await retry(Number(record.id))
                          dataGridRef.current?.refresh()
                        } finally {
                          setSending(false)
                        }
                      }}/>
                  </Tooltip>
                </ShowIf>
              </Space>
            ),
          },
        ]}
      />
      <JsonViewer
        open={openJsonViewer}
        onClose={() => setOpenJsonViewer(false)}
        content={jsonContent}
      />
    </div>
  )
}
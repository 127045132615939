import { Item, ItemImage } from '../type'
import { Button, Form, Space } from 'antd'
import { Combo, ShowIf } from '../../../core/component'
import React, { useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { ItemFindRequest } from '../type/item-query'
import { GetItemsForUpdateImages } from '../graphql/item.graphql'
import { ItemFormUpdateImagesEntry } from './ItemFormUpdateImagesEntry'
import { SaveFilled } from '@ant-design/icons'
import { notify, translate } from '../../../core'
import { useItemData } from '../hook/item-data-hook'

export const ItemFormUpdateImages = () => {
  const [form] = Form.useForm()
  const [itemsFind] = useLazyQuery<QueryPageableResponse<Item, 'itemsFind'>, QueryPageableRequest<ItemFindRequest>>(GetItemsForUpdateImages)
  const [formChanged, setFormChanged] = useState<boolean>(false)
  const {updateImages} = useItemData()
  const [saving, setSaving] = useState<boolean>(false)
  const [selectedId, setSelectedId] = useState<number>()

  const handleItemSelect = (item: Item) => {
    form.setFieldsValue(item)
    setSelectedId(item?.id)
  }

  const handleSave = async ({id, images}: { id: number, images: ItemImage[] }) => {
    setSaving(true)
    try {
      await updateImages(id, {
        images: images.map(img => ({
          filename: img.filename,
          originalFilename: img.originalFilename,
        })) ?? [],
      })

      notify.success(
        translate('images'),
        translate('save_success_message'),
      )

    } finally {
      setSaving(false)
    }
  }

  return (
    <Form
      className="p-3"
      onFieldsChange={() => setFormChanged(true)}
      onFinish={handleSave}
      form={form}
      name="form"
    >
      <Space>
        <Form.Item
          label={translate('item')}
          name="id"
        >
          <Combo<Item, number>
            style={{width: 200}}
            onSelect={(_, e) => handleItemSelect(e)}
            fetch={async ({request, search}) => {
              const {data} = await itemsFind({
                variables: {
                  request: {
                    sku: search,
                    skip: request?.skip ?? 0,
                    take: request?.take ?? 10,
                    ...request,
                    showNoEffects: true,
                  },
                },
              })

              const list = data?.itemsFind.data ?? []

              return list.map(e => ({
                value: e.id || 0,
                display: `${e.sku} - ${e.description}`,
                item: e || {},
              }))
            }}
          />
        </Form.Item>

        <Form.Item>
          <Button
            loading={saving}
            onClick={form.submit}
            type="primary"
            disabled={!formChanged}
            icon={<SaveFilled/>}
          >
            {translate('save')}
          </Button>
        </Form.Item>
      </Space>

      <ShowIf
        condition={!!selectedId}
        replacement={<></>}
      >
        <Form.Item noStyle name="images">
          <ItemFormUpdateImagesEntry/>
        </Form.Item>
      </ShowIf>
    </Form>
  )
}
import { translate } from '../../../core';
import { Checkbox, Col, Divider, Form, Input, Row } from 'antd';
import { Combo, enumToComboOptionTransformer, NumericInput, Section } from '../../../core/component';
import { Sex } from '../type';
import { ItemFormJeweleryStone } from './ItemFormJeweleryStone';
import styles from "./ItemForm.module.scss"

export const ItemFormJewelery = () => {
  return (
    <>
      <Section noMargin>
        <Divider
          orientation='left'
          className='fw-bold mt-0 mb-1'
        >
          { translate('jewelery') }
        </Divider>

        <Row gutter={ [ 16, 16 ] }>
          <Col span={ 9 }>
            <Form.Item
              className={styles["types-first-input"]}
              label={ translate('name') }
              name={ [ 'jewelery', 'name' ] }
            >
              <Input/>
            </Form.Item>
          </Col>

          <Col span={ 7 }>
            <Form.Item
              label={ translate('lady', '/', 'gentleman') }
              name={ [ 'jewelery', 'gender' ] }
            >
              <Combo
                size='middle'
                options={ enumToComboOptionTransformer(Sex) }
              />
            </Form.Item>
          </Col>

          <Col span={ 8 } className='d-flex align-items-center justify-content-center'>
            <Form.Item
              noStyle
              name={ [ 'jewelery', 'estate' ] }
              valuePropName="checked"
            >
              <Checkbox
              >
                { translate('estate') }
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={ [ 16, 16 ] }>
          <Col span={ 8 }>
            <Form.Item
              className={styles["types-first-input"]}
              label={ translate('material') }
              name={ [ 'jewelery', 'material' ] }
            >
              <Input/>
            </Form.Item>
          </Col>

          <Col span={ 4 }>
            <Form.Item
              label={ translate('weight_gr') }
              name={ [ 'jewelery', 'weightGR' ] }
            >
              <NumericInput/>
            </Form.Item>
          </Col>

          <Col span={ 4 }>
            <Form.Item
              label={ translate('dwt') }
              name={ [ 'jewelery', 'dwt' ] }
            >
              <Input/>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={ [ 16, 16 ] }>
          <Col span={ 6 }>
            <Form.Item
              className={styles["types-first-input"]}
              label={ translate('height') }
              name={ [ 'jewelery', 'height' ] }
            >
              <NumericInput/>
            </Form.Item>
          </Col>

          <Col span={ 5 }>
            <Form.Item
              label={ translate('length') }
              name={ [ 'jewelery', 'length' ] }
            >
              <NumericInput/>
            </Form.Item>
          </Col>

          <Col span={ 5 }>
            <Form.Item
              label={ translate('width') }
              name={ [ 'jewelery', 'width' ] }
            >
              <NumericInput/>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={ [ 16, 16 ] }>
          <Col span={ 8 }>
            <Form.Item
              className={styles["types-first-input"]}
              label={ translate('labor_cost') }
              name={ [ 'jewelery', 'laborCost' ] }
            >
              <NumericInput/>
            </Form.Item>
          </Col>

          <Col span={ 8 }>
            <Form.Item
              label={ translate('cost') }
              name={ [ 'jewelery', 'cost' ] }
            >
              <NumericInput/>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={ [ 16, 16 ] }>
          <Col span={ 6 }>
            <Form.Item
              className={styles["types-first-input"]}
              label={ translate('chain_type') }
              name={ [ 'jewelery', 'chainType' ] }
            >
              <Input/>
            </Form.Item>
          </Col>

          <Col span={ 5 }>
            <Form.Item
              label={ translate('clasp') }
              name={ [ 'jewelery', 'clasp' ] }
            >
              <Input/>
            </Form.Item>
          </Col>

          <Col span={ 5 }>
            <Form.Item
              label={ translate('finger_size') }
              name={ [ 'jewelery', 'fingerSize' ] }
            >
              <NumericInput
                decimalPlaces={ 2 }
              />
            </Form.Item>
          </Col>
        </Row>
      </Section>
      <ItemFormJeweleryStone/>
    </>
  )
}


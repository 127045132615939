import { gql, TypedDocumentNode } from '@apollo/client'
import { QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { Order, OrderFindRequest } from '../type'

export const GetOrders = gql`
    query GetOrders($request: OrderFindRequest) {
        ordersFind(request: $request) {
            data {
                id
                number
                date
                customer {
                    id
                    company
                    firstName
                    lastName
                    phones {
                        type
                        number
                    }
                }
                weddingRegistry {
                    id
                }
                customerEmail
                customerCell
                customerHome
                customerWork
                addresses {
                    type
                    firstName
                    lastName
                    street
                    city
                    country
                    state
                    zipCode
                    address1
                    address2
                }
                theShipmentAddressIsTheSameAsBilling
                brideGroomEnabled
                lines {
                    item {
                        id
                        sku
                        quantity {
                            onHand
                            display
                        }
                    }
                    isRepair
                    repairVersion
                    quantity
                    description
                    takeWithQuantity
                    backOrderQuantity
                    unitPrice
                    taxable
                }
                totalPrice
                taxRate
                tax
                freight
                specialInstruction
                repairs {
                    version
                    status
                    completionDate
                    pickDate
                    expectedDate
                    item
                    work
                    by
                    disposition
                    storage
                    specialInstruction
                }
                payments {
                    date
                    method
                    cardNumber
                    expireYear
                    expireMonth
                    amount
                    authorizationCode
                }
                clerk
                online
            }
            count
        }
    }
`

export const GetOnlineOrders: TypedDocumentNode<QueryPageableResponse<Order, 'ordersFind'>, QueryPageableRequest<OrderFindRequest>> = gql`
    query GetOnlineOrders($request: OrderFindRequest!) {
        ordersFind(request: $request) {
            data {
                id
                number
                date
            }
            count
        }
    }
`

export const GetOrderPage = gql`
    query GetOrderPage($number: Int!) {
        orderPage(number: $number)
    }
`

export const GetOrderNewNumber = gql`
    query GetOrderNewNumber {
        orderNewNumber
    }
`

export const GetOrderPrint = gql`
    query GetOrderPrint($id: Int!) {
        orderPrint(id: $id) {
            number
            customerNumber
            salesPerson
            date
            soldToName
            soldToAddress
            soldToCityStateZipCode
            soldToPhone
            shipToName
            shipToAddress
            shipToCityStateZipCode
            lines {
                itemNumber
                quantity
                description
                takeWithQuantity
                backOrderQuantity
                unitPrice
                amount
            }
            specialInstructions
            lastPayDate
            lastPayMethod
            lastPayCartNumber
            lastPayAuthenticationCode
            subTotal
            tax
            ship
            total
            totalDeposit
            currentPayment
            balanceDue
            clerk
            canShowSpecialInstructions
            today
            sameAddress
            brideGroomEnabled
        }
    }
`

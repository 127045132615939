import { Identity } from '../../../core/type'
import { User } from '../../user/type'
import { Contact } from '../../contact/type/contact'
import { ItemGroup } from './item-group'
import { ItemCategory } from './item-category'

export enum ItemType {
  Jewelery = 'Jewelery',
  Watch = 'Watch',
  Stone = 'Stone',
  Giftware = 'Giftware',
  Tableware = 'Tableware',
}

export enum Sex {
  Lady = 'Lady',
  Gentleman = 'Gentleman'
}

export enum WatchShape {
  Round = 'Round',
  Square = 'Square',
  ROUND = 'ROUND',
  S = 'S',
  O = 'O',
  SQUARE = 'SQUARE',
  VENTURA = 'VENTURA',
  RECTANGLE = 'RECTANGLE',
  R = 'R',
  Ventura = 'Ventura',
  SQ = 'SQ',
  TONNEAU = 'TONNEAU',
  O_S = 'O/S',
  E = 'E',
  TRI = 'TRI',
  REC = 'REC',
  OVAL = 'OVAL',
  TANK = 'TANK',
  G = 'G',
  SS = 'SS',
  T = 'T',
  SW = 'SW',
  r = 'r',
  TRIAN = 'TRIAN',
}

export enum WatchMovement {
  Q = 'Q',
  S = 'S',
  J = 'J',
  a = 'a',
  W = 'W',
  A = 'A',
  C = 'C',
  q = 'q',
  G = 'G',
  M = 'M',
}

export enum StoneShape {
  Round = 'Round',
  Oval = 'Oval',
  Marquise = 'Marquise',
  Baguette = 'Baguette',
  Pear = 'Pear',
  Heart = 'Heart',
  Triangle = 'Triangle',
  Radiant = 'Radiant',
  Square = 'Square',
  Emerald = 'Emerald',
  Princess = 'Princess',
  Cushion = 'Cushion',
  Asscher = 'Asscher'
}

export interface ItemJewelery {
  name: string
  gender: Sex
  estate: boolean
  weightGR: number
  cost: number
  laborCost: number
  material: string
  dwt: string
  chainType: string
  clasp: string
  width: number
  length: number
  height: number
  fingerSize: number
  stones: ItemJeweleryStone[]
}

export interface ItemJeweleryStone {
  name: string
  color: string
  shape: StoneShape | null
  clarity: string
  cut: string
  averageWeight: number
  quantity: number
  totalWeight: number
  costCT: number
  totalCost: number
}

export interface ItemQuantity {
  onHand: number
  display: number
  committed: number
  onOrder: number
  backOrder: number
  min: number
  max: number
  inSet: number
}

export interface ItemWatch {
  name: string
  serialNumber: string
  colorCase: string
  caseNumber: string
  band: string
  forceColor: string
  material: string
  type: Sex
  shape: WatchShape
  movement: string
  dial: string
}


export interface ItemImage {
  filename: string
  originalFilename?: string
}

export interface ItemStone {
  name: string
  parcelNumber: string
  averageWeight: number
  numberOfPieces: number
  totalWeight: number
  shape: StoneShape
  color: string
  clarity: string
  cut: string
  costCT: number
  priceCT: number
  rap: number
  offPercent: number
  make: string
  note: string
  saleQuantity: number
  mountQuantity: number
  balanceQuantity: number
  saleCarets: string
  mountCarets: string
  balanceCarets: string
  certificateNumber: string
  certificateDate: Date
  certificateImage: string
  lab: string
  measurements: string
  depht: number
  table: number
  gridle: string
  culet: string
  polish: string
  synmetry: string
  fluorecence: string
}

export interface ItemGiftwareTableware {
  discounted: boolean
  discontinuedDate: Date | null
  patternNumber: string
  dimensions: string
  sequence: string
}

export interface ItemSales {
  yearToDateQuantity: number
  yearToDateAmount: number
  lastYearQuantity: number
  lastYearAmount: number
}

export interface Item extends Identity {
  createdBy: User
  dateOfPurchase: Date
  sku: string
  style: string
  description: string
  catalogDescription: string
  notes: string
  type: ItemType
  showOnline: boolean
  mounting: boolean
  isComponent: Boolean
  category: ItemCategory
  subcategory: ItemCategory
  subcategory2: ItemCategory
  group: ItemGroup
  vendor: Contact
  designer: string
  costPrice: number
  listPrice: number
  oursPrice: number
  costSale: number
  listSale: number
  oursSale: number
  saleStartDate: Date
  saleEndDate: Date
  patternNumber: string
  quantity: ItemQuantity
  jewelery: ItemJewelery
  watch: ItemWatch
  stone: ItemStone | null
  giftware?: ItemGiftwareTableware
  tableware?: ItemGiftwareTableware
  sales: ItemSales
  components: ItemComponent[]
  parents: ItemComponentParent[]
  images: ItemImage[]
  vrnPackages: Item[]
}


export interface ItemComponent {
  component: Item
  quantity: number
}

export interface ItemComponentParent {
  item: Item
  quantity: number
}


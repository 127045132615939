import { Button, Col, Form, Input, Row, Tooltip } from 'antd'
import { InputCard } from '../../inventory/component/InputCard'
import { translate } from '../../../core'
import { DateDisplay, ShowIf } from '../../../core/component'
import { EyeOutlined } from '@ant-design/icons'
import { useContext } from 'react'
import { PageContextValue } from '../../../core/type'
import { Order, OrderEntry } from '../type'
import { OrderPageContext } from './OrderPageContext'

export const OrderFormRepairsDisplay = ({onSelectRepair}: { onSelectRepair: (version: string) => void }) => {
  const {form} = useContext<PageContextValue<Order, OrderEntry>>(OrderPageContext)

  return (
    <Form.List name='repairs'>
      {fields => (
        <ShowIf
          condition={!!fields.length}
          replacement={<></>}
        >
          <InputCard
            title={translate('repair')}
          >
            <Row gutter={[8, 8]}>
              <Col span={2}>
                <label>{translate('version')}</label>
              </Col>

              <Col span={10}>
                <label>{translate('item')}</label>
              </Col>

              <Col span={7}>
                <label>{translate('work')}</label>
              </Col>

              <Col span={3}>
                <label>{translate("completion", "date")}</label>
              </Col>
            </Row>
            {
              fields.map(field => (
                <Row gutter={[8, 8]} className='mt-1'>
                  <Col span={2}>
                    <Form.Item
                      noStyle
                      name={[field.name, 'version']}
                    >
                      <Input
                        disabled={true}
                        className='input-disable-clear text-center'
                      />
                    </Form.Item>
                  </Col>

                  <Col span={10}>
                    <Form.Item
                      noStyle
                      name={[field.name, 'item']}
                    >
                      <Input
                        disabled={true}
                        className='input-disable-clear'
                      />
                    </Form.Item>
                  </Col>

                  <Col span={7}>
                    <Form.Item
                      noStyle
                      name={[field.name, 'work']}
                    >
                      <Input
                        disabled={true}
                        className='input-disable-clear'
                      />
                    </Form.Item>
                  </Col>

                  <Col span={3}>
                    <Form.Item
                      noStyle
                      name={[field.name, 'completionDate']}
                    >
                      <DateDisplay/>
                    </Form.Item>
                  </Col>

                  <Col span={2} className='d-flex flex-row align-items-center'>
                    <Tooltip title={translate('view')}>
                      <Button
                        icon={<EyeOutlined/>}
                        shape='circle'
                        type='text'
                        onClick={() => {
                          const version = form?.getFieldValue(['repairs', field.name, 'version'])
                          version && onSelectRepair(version)
                        }}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              ))
            }
          </InputCard>
        </ShowIf>
      )}
    </Form.List>
  )
}



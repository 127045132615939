import { FormEntryField } from '../../../core/type'
import { Item } from '../type'
import { CSSProperties, FC, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GetItemsForLookup } from '../graphql/item.graphql'
import { Combo } from '../../../core/component'

export const ItemFormSelector: FC<FormEntryField<number> & { onSelect?: (item: Item) => void, style?: CSSProperties }>
  = ({
       onChange,
       value,
       onSelect,
       style,
     }) => {
  const [search, setSearch] = useState<string>()
  const {data, loading} = useQuery(GetItemsForLookup, {
    variables: {
      request: {
        take: 10,
        skip: 0,
        search,
      },
    }
  })

  return (
    <Combo<Item, number>
      style={style}
      loading={loading}
      value={value}
      options={data?.itemsFind.data.map(it => ({
        value: it.id,
        display: it.sku,
        item: it,
      }))}
      onSearch={setSearch}
      onChange={value => onChange!(value as number)}
      onSelect={(_, item: Item) => onSelect && onSelect!(item)}
    />
  )
}
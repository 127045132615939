import { Col, Form, Input, Row } from 'antd'
import { AutoComplete } from '../../../core/component'
import { translate } from '../../../core'
import { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { useLazyQuery } from '@apollo/client'
import { CityAndCountryAndStateQueryResponse } from '../../shared/type'
import { GetAllCitiesAndCountriesAndStates } from '../../shared/graphql/location-queries.graphql'

export const AppraisalFormAddress = () => {
  const [ cities, setCities ] = useState<string[]>([])
  const [ countries, setCountries ] = useState<string[]>([])
  const [states, setStates] = useState<string[]>([])
  const [ getAllCitiesAndCountriesAndStates ] = useLazyQuery<CityAndCountryAndStateQueryResponse>(GetAllCitiesAndCountriesAndStates)

  const fetch = async () => {
    const { data } = await getAllCitiesAndCountriesAndStates()
    const cityList = (data?.cityList ?? []).map(c => c.name)
    const countryList = (data?.countryList ?? []).map(c => c.name)
    const states = (data?.statesFind?.data ?? []).map(s => s.key)

    setCities(cityList)
    setCountries(countryList)
    setStates(states)
  }

  useEffect(() => {
    fetch()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Row style={ { height: '40px' } }>
        <Col span={ 10 } className={ styles['wrapper'] }>
          <Form.Item
            name={ [ 'customerAddress', 'street' ] }
            label={ translate('street') }
            labelCol={{span: 3}}
            labelAlign='left'
          >
            <Input style={{ width: "19rem"}} />
          </Form.Item>
        </Col>
        <Col span={ 6 } className={ styles['wrapper'] }>
          <Form.Item
            name={ [ 'customerAddress', 'line1' ] }
            label={ translate('line', '1') }
            labelCol={{span: 5}}
            labelAlign='left'
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col span={ 6 } className={ styles['wrapper'] }>
          <Form.Item
            name={ [ 'customerAddress', 'line2' ] }
            label={ translate('line', '2') }
            labelCol={{span: 5}}
            labelAlign='left'
          >
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <Row style={ { height: '40px' } }>
        <Col span={ 6 } className={ styles['wrapper'] }>
          <Form.Item
            label={ translate('city') }
            name={ [ 'customerAddress', 'city' ] }
            labelCol={{span: 4}}
            labelAlign='left'
          >
            <AutoComplete items={ cities } style={ { width: '10.3rem' } }/>
          </Form.Item>
        </Col>
        <Col span={ 6 } className={ styles['wrapper'] }>
          <Form.Item
            label={ translate('state') }
            name={ [ 'customerAddress', 'state' ] }
            labelCol={{span: 5}}
            labelAlign='left'
          >
            <AutoComplete items={ states } style={ { width: '10.3rem' } }/>
          </Form.Item>
        </Col>

        <Col span={ 6 } className={ styles['wrapper'] }>
          <Form.Item
            label={ translate('country') }
            name={ [ 'customerAddress', 'country' ] }
            labelCol={{span: 6}}
            labelAlign='left'
          >
            <AutoComplete style={ { width: '10.3rem' } } items={ countries }/>
          </Form.Item>
        </Col>
        <Col span={ 4} className={ styles['wrapper'] }>
          <Form.Item
              name={ [ 'customerAddress', 'zipCode' ] }
              label={translate('zip_code')}
              labelCol={{span: 12}}
              labelAlign='left'
          >
            <Input style={ { width: '4rem' } }/>
          </Form.Item>
        </Col>

      </Row>

    </>
  )
}

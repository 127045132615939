import React, { useContext } from 'react'
import { translate } from '../../../core'
import { Item, ItemCategoryLevel, ItemEntry, ItemType } from '../type'
import { Combo } from '../../../core/component'
import { PageContextValue, QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { ItemPageContext } from './ItemPageContext'
import { Contact } from '../../contact/type/contact'
import { stringConcat } from '../../../core/until'
import { getItemTypeComboOptions } from '../func/type-options'
import { ItemFindRequest, ItemPageRequest } from '../type/item-query'
import { useLazyQuery } from '@apollo/client'
import { GetItemPage } from '../graphql/item.graphql'
import { ContactFindRequest } from '../../contact/type/contact.query'
import { GetVendorsForLookup } from '../../contact/graphql/contact.queries.graphql'
import { SearchModal, SearchProps } from '../../../core/component/Search'
import { ItemSearchCategorySingle } from './ItemSearchCategorySingle'

export const ItemSearch = ({open, closeMe}: SearchProps) => {
  const {fetch} = useContext<PageContextValue<Item, ItemEntry>>(ItemPageContext)
  const [findPage, {loading}] = useLazyQuery<{ itemPage: number }, { request: ItemPageRequest }>(
    GetItemPage,
    {errorPolicy: 'all'}
  )
  const [findVendors] = useLazyQuery<
    QueryPageableResponse<Contact, 'contactsFind'>,
    QueryPageableRequest<ContactFindRequest>>(GetVendorsForLookup)

  return (
    <SearchModal<ItemFindRequest>
      open={open}
      closeMe={closeMe}
      onComplete={(request, page) => {
        fetch!(request, page)
        closeMe()
      }}
      title={translate('search', 'item')}
      idLabel={translate('item', '/', 'sku', 'number_abbr')}
      searchOptions={[
        {
          key: 'type',
          label: translate('type'),
          render: ({isActive}) =>
            <Combo<any, ItemType>
              disabled={!isActive}
              allowMultiple
              size='middle'
              className='w-100'
              options={getItemTypeComboOptions()}
            />,
          setValue: (params, request) => {
            request.types = params.value
          },
          hideExactMatch: true,
        },
        {
          key: 'vendor',
          label: translate('vendor'),
          render: ({isActive}) =>
            <Combo<Contact, number>
              disabled={!isActive}
              size='middle'
              className='w-100'
              fetch={async ({request, search}) => {
                const {data} = await findVendors({
                  variables: {
                    request: {
                      take: request?.take ?? 100,
                      skip: request?.skip ?? 0,
                      searchVendor: search,
                    }
                  }
                })

                const list = data?.contactsFind.data ?? []

                return list.map((e) => ({
                  value: e.id || 0,
                  display: stringConcat(e.contactId, ' - ', e.company),
                  item: e || {},
                }))
              }}
            />,
          hideExactMatch: true,
          setValue: (params, request) => {
            request.vendorId = params.value
          },
        },
        {
          key: 'style',
          label: translate('style', '/', 'pattern'),
          setValue: (params, request) => {
            request.style = params.value
            request.styleExactMatch = params.exactMatch
          }
        },
        {
          key: 'category',
          label: translate('category'),
          render: ({isActive}) => (
            <ItemSearchCategorySingle
              disabled={!isActive}
              level={ItemCategoryLevel.Category}
            />
          ),
          setValue: (params, request) => {
            request.categoryTitle = params.value
          },
          hideExactMatch: true,
        },
        {
          key: 'subcategory',
          label: translate('subcategory'),
          render: ({isActive}) => (
            <ItemSearchCategorySingle
              disabled={!isActive}
              level={ItemCategoryLevel.Subcategory}
            />
          ),
          setValue: (params, request) => {
            request.subcategoryTitle = params.value
          },
          hideExactMatch: true,
        },
        {
          key: 'subcategory2',
          label: translate('subcategory2'),
          render: ({isActive}) => (
            <ItemSearchCategorySingle
              disabled={!isActive}
              level={ItemCategoryLevel.Subcategory2}
            />
          ),
          setValue: (params, request) => {
            request.subcategory2Title = params.value
          },
          hideExactMatch: true,
        },
        {
          key: 'patternNumber',
          label: translate('pattern_number_abbr'),
          setValue: (params, request) => {
            request.patternNumber = params.value
            request.patternNumberExactMatch = params.exactMatch
          },
        },
      ]}
      findPage={async id => {
        const {data, error} = await findPage({
          variables: {
            request: {
              sku: id.toString(),
            },
          },
        })

        return {
          page: data?.itemPage ?? 0,
          error: error?.graphQLErrors,
        }
      }}
      findPageLoading={loading}
      sort={{
        fields: [
          {value: 'sku', display: translate('sku')},
          {value: 'style', display: translate('style')},
          {value: 'category', display: translate('category')},
          {value: 'subcategory', display: translate('subcategory')},
          {value: 'subcategory2', display: translate('subcategory2')},
        ],
      }}
    />
  )
}

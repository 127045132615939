import {
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveFilled,
  SearchOutlined,
  StopOutlined
} from '@ant-design/icons'
import { Button, Empty, Form } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { confirm, notify, translate } from '../../../core'
import { Container, ErrorMessage, SinglePageable, SinglePageableResponse } from '../../../core/component'
import {
  BadRequestException,
  EntryMode,
  PageableRequest,
  PageContextValue,
  QueryPageableRequest,
  QueryPageableResponse,
} from '../../../core/type'
import { WeddingRegistryForm } from './WeddingRegistryForm'
import { WeddingRegistryPageContext } from './WeddingRegistryPageContext'
import styles from './WeddingRegistry.module.scss'
import { WeddingRegistry, WeddingRegistryEntry, WeddingRegistryFindRequest } from '../type'
import { WeddingRegistrySearch } from './WeddingRegistrySearch'
import { GetWeddingRegistries } from '../graphql/wedding-registry-queries'
import { useLazyQuery } from '@apollo/client'
import { useWeddingRegistryData } from '../hook/wedding-registry-data-hook'

export const WeddingRegistryPage = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [sending, setSending] = useState<boolean>(false)
  const [entity, setEntity] = useState<WeddingRegistryEntry>()
  const [page, setPage] = useState<number | null>()
  const [errors, setErrors] = useState<string[]>([])
  const [searchOpen, setSearchOpen] = useState<boolean>(false)
  const [form] = Form.useForm<WeddingRegistryEntry>()
  const [find] = useLazyQuery<QueryPageableResponse<WeddingRegistry, 'weddingRegistryFind'>,
    QueryPageableRequest<WeddingRegistryFindRequest>>(GetWeddingRegistries)
  const [search, setSearch] = useState<WeddingRegistryFindRequest>()
  const {toEntry, toDto, create, update, remove, init} = useWeddingRegistryData()
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false)
  const [entryMode, setEntryMode] = useState<EntryMode>(EntryMode.Nothing)

  useEffect(() => {
    setIsFormChanged(false)
  }, [entity])

  const add = async () => {
    try {
      setLoading(true)
      const entity = await init()
      setEntity(toEntry(entity))
      form.resetFields()
      form.setFieldsValue(toEntry(entity))
      setEntryMode(EntryMode.Modify)
    } finally {
      setLoading(false)
    }
  }
  const save = async (data: WeddingRegistryEntry) => {
    setSending(true)
    setErrors([])
    const dto = toDto(data)
    const id = entity?.id
    try {
      if (id) await update(id, dto)
      else {
        const result = await create(dto)
        if (entity) entity.id = result.id
      }
      notify.success(
        'Wedding',
        translate('save_success_message'),
      )
    } catch (e) {
      if (e instanceof BadRequestException) setErrors(e.message)
    } finally {
      setSending(false)
    }
  }

  const handleRemove = async () => {
    if (!entity) return

    const result = await confirm(
      translate('remove_confirmation_message', 'order', '?'),
    )

    if (!result) return

    await remove(entity.id)

    notify.success(
      translate('remove', 'order'),
      translate('the', 'order', 'remove_success_message'),
    )

    setEntity(undefined)
  }

  const onChange = async (
    request: PageableRequest,
    page: number,
  ): Promise<SinglePageableResponse<WeddingRegistry>> => {
    setLoading(true)

    try {
      const {data} = await find({
        variables: {
          request: {
            take: request.take ?? 1,
            skip: request.skip ?? 0,
            ...search,
          },
        },
      })

      const [entity] = data?.weddingRegistryFind.data ?? []
      setErrors([])
      const entry = entity && toEntry(entity as any)
      if (entity) {
        toEntry(entity)
        setEntity(entry)
        form.setFieldsValue(entry)
        setEntryMode(EntryMode.Modify)
      } else {
        setEntity(undefined)
        setEntryMode(EntryMode.Nothing)
      }

      if (!page) setPage(1)

      return {
        count: data?.weddingRegistryFind.count ?? 0
      }
    } finally {
      setLoading(false)
    }
  }

  const fetch = (search: any, page?: number) => {
    setSearch(search)
    setPage(0)
    setTimeout(() => setPage(page), 500)
  }

  const refresh = async () => {
    const {data} = await find({
      variables: {
        request: {
          take: 1,
          skip: 0,
          id: entity?.id,
        },
      },
    })

    const [item] = data?.weddingRegistryFind.data ?? []
    setEntity(toEntry(item))
  }

  const close = () => {
    setEntity(undefined)
    setEntryMode(EntryMode.Nothing)
  }

  const contextValue = useMemo<PageContextValue<any, any>>(() => ({
    form,
    save,
    entity,
    refresh,
    fetch,
    onFormChange: () => setIsFormChanged(true)
  }), [form, save, entity, refresh, fetch])

  return (
    <WeddingRegistryPageContext.Provider
      value={contextValue}
    >
      <Container
        leftClassName="action-container"
        paddingClass="0"
        loading={loading}
        toolbarItems={{
          start: [
            <Button
              key={`weddingRegistryPage-search`}
              onClick={() => setSearchOpen(true)}
              icon={<SearchOutlined/>}
            >
              {translate('search')}
            </Button>,

            <Button key={`weddingRegistryPage-add`} onClick={add} icon={<PlusOutlined/>}>
              {translate('add')}
            </Button>,

            <Button
              key={`weddingRegistryPage-save`}
              loading={sending}
              onClick={() => form.submit()}
              disabled={!entity}
              type={isFormChanged ? 'primary' : 'default'}
              icon={<SaveFilled/>}
            >
              {translate('save')}
            </Button>,

            <Button
              key={`weddingRegistryPage-remove`}
              danger
              disabled={!(entity?.id)}
              onClick={handleRemove}
              icon={<DeleteOutlined/>}
            >
              {translate('remove')}
            </Button>,

            <Button
              disabled={entryMode === EntryMode.Nothing}
              onClick={async () => {
                if (entryMode === EntryMode.New) {
                  form.resetFields()
                  setIsFormChanged(false)
                }
                if (entryMode === EntryMode.Modify)
                  await refresh()
              }}
              icon={<StopOutlined/>}
              key={translate('clear')}
            >
              {translate('clear')}
            </Button>,

            <Button
              disabled={entryMode === EntryMode.Nothing}
              key={translate('exit')}
              onClick={close}
              icon={<CloseOutlined/>}
            >
              {translate('exit')}
            </Button>,
          ],
          end: [
            <SinglePageable key={`weddingRegistryPage-pagination`} onChange={onChange} defaultPage={page}/>
          ],
        }}
      >
        <WeddingRegistrySearch
          open={searchOpen}
          closeMe={() => setSearchOpen(false)}
        />

        {entity ? (
          <div
            className={['px-3 bg-gray', styles['orderEntryForm']].join(
              ' ',
            )}
          >
            <ErrorMessage
              title={translate('entry_fail_message', 'order')}
              message={errors}
            />

            <WeddingRegistryForm/>
          </div>
        ) : (
          <Empty className="mt-4"/>
        )}
      </Container>
    </WeddingRegistryPageContext.Provider>
  )
}

import { gql, TypedDocumentNode } from '@apollo/client'
import { ContactRelationCreateInput, ContactRelationUpdateInput } from '../type/contact-relation.dto'

export const ContactRelationCreateMutation: TypedDocumentNode<void, {
  parentId: number
  input: ContactRelationCreateInput
}> = gql`
    mutation contactRelationCreate($parentId: Int!, $input: ContactRelationCreateInput!) {
        contactRelationCreate(parentId: $parentId, input: $input)
    }
`

export const ContactRelationUpdateMutation: TypedDocumentNode<void, {
  id: number
  input: ContactRelationUpdateInput
}> = gql`
    mutation contactRelationUpdate($id: Int!,$input: ContactRelationUpdateInput!) {
        contactRelationUpdate(id: $id, input: $input)
    }
`

export const ContactRelationRemoveMutation: TypedDocumentNode<void, {id: number}> = gql`
    mutation contactRelationRemove($id: Int!) {
        contactRelationRemove(id: $id)
    }
`
import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, Input, Row, Tooltip, Typography } from 'antd'
import { Combo, ComboFetchFuncParameters, NumericInput } from '../../../core/component'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { translate } from '../../../core'
import { resolveNumber } from '../../../core/until'
import styles from './WeddingRegistry.module.scss'
import { Item } from '../../inventory/type'
import { PageContextValue, QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { OrderLine } from '../../order/type'
import { WeddingRegistryEntry, WeddingRegistry } from '../type'
import { WeddingRegistryPageContext } from './WeddingRegistryPageContext'
import { mapEntryLine } from '../model-mapper/wedding-registry-entity-to-entry'
import { getOrderPrice } from '../../order/method/order-pricing'
import { useLazyQuery } from '@apollo/client'
import { ItemFindRequest } from '../../inventory/type/item-query'
import { GetItemsForLookup } from '../../inventory/graphql/item.graphql'

type WeddingRegistryLinesProps = {
  totalPrice: number
  onAmountsChanged: () => void
}

export const WeddingRegistryLines = ({onAmountsChanged, totalPrice}: WeddingRegistryLinesProps) => {
  const [forceUpdate, setForceUpdate] = useState<boolean>(false)
  const {form} =
    useContext<PageContextValue<WeddingRegistry, WeddingRegistryEntry>>(WeddingRegistryPageContext)
  const [itemsFind] =
    useLazyQuery<QueryPageableResponse<Item, 'itemsFind'>, QueryPageableRequest<ItemFindRequest>>(GetItemsForLookup)

  const itemFetch = async ({
                             request,
                             value,
                             search,
                           }: ComboFetchFuncParameters<number>) => {
    const showOnWebRequest: ItemFindRequest = value
      ? {}
      : {showOnWeb: true}

    const {data} = await itemsFind({
      variables: {
        request: {
          id: value,
          search,
          take: request?.take ?? 10,
          skip: request?.skip ?? 0,
          showNoEffects: true,
          ...showOnWebRequest,
        },
      },
    })

    const list = data?.itemsFind.data ?? []

    return list.map((e) => ({
      value: e.id || 0,
      display: e.sku,
      item: e || {},
    }))
  }

  const onSelectItem = (index: number) => (_: any, item: Item) => {
    const fieldPath = ['lines', index]
    form?.setFieldValue([...fieldPath, 'description'], item.description)
    form?.setFieldValue([...fieldPath, 'unitPrice'], getOrderPrice(item))
    form?.setFieldValue([...fieldPath, 'taxable'], true)
    onLineChanged(index)
  }

  const onLineChanged = (index: number) => {
    const fieldPath = ['lines', index]
    const entry = form?.getFieldValue(fieldPath)
    const quantity = resolveNumber(entry.quantity)
    const quantityTaken = resolveNumber(entry.quantityTaken)
    const quantityAvailable = quantity - quantityTaken
    const line = form?.getFieldValue(fieldPath)

    mapEntryLine(line)

    form?.setFieldValue(fieldPath, line)

    form?.setFieldValue(
      [...fieldPath, 'quantityAvailable'],
      quantityAvailable >= 0 ? quantityAvailable : 0,
    )
    onAmountsChanged()
  }

  useEffect(() => {
    setForceUpdate(false)
  }, [forceUpdate])

  return (
    <Form.List name="lines">
      {(fields, {add, remove}) => (
        <>
          <Row
            align="middle"
            justify="space-between"
            style={{width: '70%'}}
            className={styles['amountsWrapper']}
          >
            <Form.Item
              style={{
                marginBottom: '0',
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
              }}
            >
              <Button onClick={() => add({quantity: 1})} icon={<PlusOutlined/>}>
                {translate('add', 'items')}
              </Button>
            </Form.Item>

            <Form.Item
              label={translate('total_price')}>
              <NumericInput
                value={totalPrice}
                style={{width: 100}}
                className={`${styles.readOnlyInput} ${styles.amountInput}`}
                disabled
              />
            </Form.Item>
          </Row>
          <table className="table">
            <thead>
            <tr>
              <th className="text-center" style={{width: '5%'}}>
              </th>
              <th className="text-center" style={{width: '15%'}}>
                {translate('item_number_abbr')}
              </th>
              <th className="text-center" style={{width: '15%'}}>
                {translate('quantity_desired')}
              </th>
              <th className="text-center" style={{width: '30%'}}>
                {translate('description')}
              </th>
              <th className="text-center" style={{width: '10%'}}>
                {translate('unit_price')}
              </th>
              <th className="text-center" style={{width: '10%'}}>
                {translate('extended')}
              </th>
              <th className="text-center" style={{width: '15%'}}>
                {translate('quantity_available')}
              </th>
              <th className="text-center" style={{width: '3%'}}></th>
            </tr>
            </thead>

            <tbody className={styles['orderLines']}>
            {fields.map((field, index) => (
              <tr key={`${field.name}-${field.key}`}>
                <td className="d-flex align-items-start">
                  <Form.Item name={[field.name, 'repairVersion']}>
                    <Tooltip title={translate('repair', 'version')}>
                      <Typography.Link
                        className="mt-1 ms-3"
                        onClick={() => {
                          const line = form?.getFieldValue([
                            'lines',
                            index,
                          ]) as OrderLine
                        }}
                      >
                        {form?.getFieldValue([
                          'lines',
                          index,
                          'repairVersion',
                        ])}
                      </Typography.Link>
                    </Tooltip>
                  </Form.Item>
                </td>

                <td>
                  <Form.Item
                    name={[field.name, 'item', 'id']}
                    rules={[
                      {
                        required: true,
                        message: translate('missing_field_message', 'item'),
                      },
                    ]}
                    className={styles['orderLineItemNumber']}
                  >
                    <Combo<Item, number>
                      size="middle"
                      fetch={itemFetch}
                      onSelect={onSelectItem(index)}
                    />
                  </Form.Item>
                </td>

                <td>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: translate(
                          'missing_field_message',
                          'quantity_abbr',
                        ),
                      },
                    ]}
                    name={[field.name, 'quantity']}
                  >
                    <NumericInput
                      className={`text-center`}
                      onChange={() => onLineChanged(index)}
                    />
                  </Form.Item>
                </td>

                <td>
                  <Form.Item
                    noStyle
                    name={[field.name, 'description']}
                    rules={[
                      {
                        required: true,
                        message: translate(
                          'missing_field_message',
                          'description',
                        ),
                      },
                    ]}
                  >
                    <Input/>
                  </Form.Item>
                </td>

                <td>
                  <Form.Item
                    name={[field.name, 'unitPrice']}
                    rules={[
                      {
                        required: true,
                        message: translate(
                          'missing_field_message',
                          'unit_price',
                        ),
                      },
                    ]}
                  >
                    <NumericInput
                      className={styles.amountInput}
                      onChange={() => onLineChanged(index)}
                    />
                  </Form.Item>
                </td>

                <td>
                  <Form.Item name={[field.name, 'extendedPrice']}>
                    <NumericInput
                      className={`${styles.readOnlyInput} ${styles.amountInput}`}
                      disabled={true}
                    />
                  </Form.Item>
                </td>
                <td>
                  <Form.Item name={[field.name, 'quantityAvailable']}>
                    <NumericInput
                      className={`${styles.readOnlyInput} ${styles.amountInput}`}
                      disabled={true}
                      decimalPlaces={0}
                    />
                  </Form.Item>
                </td>

                <td>
                  <Tooltip title={translate('remove')}>
                    <Button
                      onClick={() => {
                        remove(index)
                        setTimeout(() => onAmountsChanged(), 100)
                      }}
                      type="text"
                      shape="circle"
                      icon={<CloseOutlined/>}
                    />
                  </Tooltip>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </>
      )}
    </Form.List>
  )
}



import { Button, Col, DatePicker, Flex, Form, Input, Row, Tooltip } from 'antd'
import { translate } from '../../../core'
import styles from './OrderFormRepair.module.scss'
import { dateDisplay, dateFormat } from '../../../core/until'
import { PlusOutlined, PrinterOutlined, SaveOutlined } from '@ant-design/icons'
import { useContext, useEffect, useState } from 'react'
import { PageableRequest, PageContextValue, QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { Order, OrderEntry, OrderFindRequest, OrderRepair } from '../type'
import { OrderPageContext } from './OrderPageContext'
import { initNewRepairLine } from '../method/initNewRepairLine'
import { PrintRepairVariables, usePrinter } from '../../../core/component/Printer/printer.hook'
import Enumerable from 'linq'
import { Contact, PhoneType } from '../../contact/type/contact'
import { gql, useLazyQuery } from '@apollo/client'
import { DraggableModal, SinglePageable } from '../../../core/component'
import { ContactFindRequest } from '../../contact/type/contact.query'

const {TextArea} = Input

export type OrderFormRepairProps = {
  open?: boolean
  version?: string
  onChangeVersion: (version: string) => void
  onClose: () => void
  onAmountChange: () => void
}

export const OrderFormRepair = ({open, onClose, version, onChangeVersion, onAmountChange}: OrderFormRepairProps) => {
  const [find] = useLazyQuery<QueryPageableResponse<Order, 'ordersFind'>, QueryPageableRequest<OrderFindRequest>>(gql`
      query GetOrders($request: OrderFindRequest) {
          ordersFind(request: $request) {
              data {
                  number
                  date
                  repairs {
                      version
                      completionDate
                      pickDate
                      expectedDate
                      item
                      work
                      specialInstruction
                  }
                  clerk
                  addresses {
                      street
                      city
                      state
                      zipCode
                      firstName
                      lastName
                  }
                  customer {
                      id
                      lastName
                      firstName
                      phones {
                          type
                          number
                      }
                  }
              }
          }
      }
  `)
  const [findContacts] = useLazyQuery<
    QueryPageableResponse<Contact, 'contactsFind'>,
    QueryPageableRequest<ContactFindRequest>>(gql`
      query GetRelationsOfContact($request: ContactFindRequest!) {
          contactsFind(request: $request) {
              data {
                  id
                  relations {
                      firstName
                      lastName
                      phones {
                          number
                      }
                  }
              }
          }
      }
  `)
  const {form, entity: entityOnForm, isFormChanged} = useContext<PageContextValue<Order, OrderEntry>>(OrderPageContext)
  const [index, setIndex] = useState<number>()
  const {print} = usePrinter()

  useEffect(() => {
    if (!version)
      return setIndex(-1)

    const repairs = form?.getFieldValue('repairs') as OrderRepair[] ?? []
    let repair = repairs.find(r => r.version === version)
    if (!repair) {
      repair = {
        version,
      } as OrderRepair
      repairs.push(repair)
      form?.setFieldValue('repairs', repairs)
    }

    setIndex(repairs.indexOf(repair) + 1)
  }, [version])

  const handleAdd = () => {
    const newVersion = initNewRepairLine(form)
    onChangeVersion(newVersion)
    onAmountChange()
  }

  const handlePageChange = (request: PageableRequest) => {
    const repairs = form?.getFieldValue('repairs') as OrderRepair[]

    const result = Promise.resolve({
      count: repairs.length,
    })

    const repair = Enumerable.from(repairs)
      .skip(request.skip)
      .take(request.take)
      .firstOrDefault()

    if (!repair)
      return result

    if (version === repair.version)
      return result

    onChangeVersion(repair.version)

    return result
  }

  const handlePrint = async () => {
    const id = entityOnForm?.id

    if (!id)
      return

    const {data} = await find({
      variables: {
        request: {
          id,
          take: 1,
          skip: 0,
        }
      }
    })

    const [entity] = data?.ordersFind.data ?? []

    if (!entity)
      return

    const {data: contactData} = await findContacts({
      variables: {
        request: {
          id: entity.customer.id,
          take: 1,
          skip: 0,
        }
      }
    })

    const [firstRelation] = contactData?.contactsFind.data[0]?.relations ?? []

    const repair = entity.repairs.find(e => e.version === version)

    if (!repair)
      return

    const [address] = entity.addresses ?? []

    const totalNumberOfSpaceFromFirstUpToDate = 25
    const customerName = `${address.lastName},${address.firstName}`
    const customerLine = [
      customerName,
      (new Array(totalNumberOfSpaceFromFirstUpToDate - customerName.length)).fill(' ').join(''),
      dateDisplay(entity.date, dateFormat),
    ].join('')

    return print<PrintRepairVariables>({
      template_name: 'repair',
      variables: {
        title: `${entity.number} ${repair.version}`,
        twoCharacters: entity.clerk,
        shortDescription: `${address.street}`,
        address: [
          address.city,
          address.state,
          address.zipCode,
        ]
          .filter(Boolean)
          .length
          ? `${address.city ?? ''}, ${address.state ?? ''} ${address.zipCode ?? ''}`
          : '',
        customerName: customerLine,
        homeNumber: entity.customer?.phones?.find(p => p.type === PhoneType.Home)?.number ?? '',
        cellNumber: entity.customer?.phones?.find(p => p.type === PhoneType.Cell)?.number ?? '',
        workNumber: entity.customer?.phones?.find(p => p.type === PhoneType.Office)?.number ?? '',
        altNumber: firstRelation?.phones[0]?.number?.toString() ?? '',
        date: repair?.completionDate ? dateDisplay(repair?.completionDate, dateFormat) : '',
        expectedDate: repair?.expectedDate
          ? dateDisplay(repair?.expectedDate, dateFormat)
          : '',
        item: repair?.item ?? '',
        work: repair?.work ?? '',
        specialInstruction: repair?.specialInstruction || '---',
        relation: [
          firstRelation?.lastName,
          firstRelation?.firstName,
        ]
          .filter(Boolean)
          .join(' '),
      }
    })
  }

  return (
    <DraggableModal
      maskClosable={false}
      style={{top: 20}}
      title={translate('order', 'repair')}
      closable
      cancelButtonProps={{style: {display: 'none'}}}
      open={open}
      okText={translate('save')}
      onCancel={onClose}
      footer={[
        <Button
          type={isFormChanged ? 'primary' : 'default'}
          icon={<SaveOutlined/>}
          onClick={form?.submit}
        >
          {translate('save')}
        </Button>,
      ]}
    >
      <Flex>
        <Flex justify='start' className='w-50'>
          <Button
            onClick={handlePrint}
            icon={<PrinterOutlined/>}
          >
            {translate('print', 'label')}
          </Button>
        </Flex>
        <Flex justify='end' className='w-50'>
          <Tooltip
            title={translate('new')}
          >
            <Button
              onClick={handleAdd}
              style={{marginRight: 8}}
              icon={<PlusOutlined/>}
            />
          </Tooltip>

          <SinglePageable
            defaultPage={index}
            hideCountCaption
            hideFirstButton
            hideLastButton
            hideInputPageNumber
            onChange={handlePageChange}
          />
        </Flex>
      </Flex>
      <RepairForm
        index={index}
      />
    </DraggableModal>
  )
}

const RepairForm = ({index}: { index?: number }) => (
  <Form.List name='repairs'>
    {
      (fields) => (
        <>
          {fields.map((field) => (
            <div style={{display: (field.name === (index ?? 0) - 1) ? 'unset' : 'none'}}>
              <Row gutter={[10, 10]} className='mt-3'>
                <Col span={6}>
                  <Form.Item
                    className={styles.formItem}
                    label={translate('status')}
                    name={[field.name, 'status']}
                  >
                    <Input style={{width: 100}}/>
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    className={styles.formItem}
                    label={translate('version')}
                    name={[field.name, 'version']}
                  >
                    <Input style={{width: 60, textAlign: 'center'}} disabled/>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[10, 10]}>
                <Col span={8}>
                  <Form.Item
                    className={styles.formItem}
                    label={translate('completion', 'date')}
                    name={[field.name, 'completionDate']}
                  >
                    <DatePicker format={dateFormat}/>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label={translate('pick', 'date')}
                    name={[field.name, 'pickDate']}
                  >
                    <DatePicker format={dateFormat}/>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label={translate('expected', 'date')}
                    name={[field.name, 'expectedDate']}
                  >
                    <DatePicker format={dateFormat}/>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                className={styles.formItem}
                label={translate('item')}
                name={[field.name, 'item']}
              >
                <TextArea size='large' rows={4}/>
              </Form.Item>

              <Form.Item
                className={styles.formItem}
                label={translate('work')}
                name={[field.name, 'work']}
              >
                <TextArea size='large' rows={4}/>
              </Form.Item>

              <Form.Item
                className={styles.formItem}
                label={translate('by')}
                name={[field.name, 'by']}
              >
                <Input/>
              </Form.Item>

              <Form.Item
                className={styles.formItem}
                label={translate('disposition')}
                name={[field.name, 'disposition']}
              >
                <Input/>
              </Form.Item>

              <Form.Item
                className={styles.formItem}
                label={translate('storage')}
                name={[field.name, 'storage']}
              >
                <Input/>
              </Form.Item>

              <Form.Item
                className={styles.formItem}
                name={[field.name, 'specialInstruction']}
                label={translate('special_instruction')}
              >
                <TextArea size='large' rows={4}/>
              </Form.Item>
            </div>
          ))}
        </>
      )
    }
  </Form.List>
)
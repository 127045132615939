import { Button, Empty, Form } from 'antd'
import { Appraisal, AppraisalEntry, AppraisalFindRequest } from '../type'
import { AppraisalForm } from './AppraisalForm'
import { AppraisalPageContext } from './AppraisalPageContext'
import { useEffect, useMemo, useState } from 'react'
import {
  BadRequestException,
  EntryMode,
  PageableRequest,
  PageContextValue,
  QueryPageableRequest,
  QueryPageableResponse,
} from '../../../core/type'
import { confirm, notify, translate } from '../../../core'
import { Container, ErrorMessage, getURL, SinglePageable, SinglePageableResponse, } from '../../../core/component'
import {
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
  PrinterOutlined,
  SaveFilled,
  SearchOutlined,
  StopOutlined,
} from '@ant-design/icons'
import { EnvelopIcon } from '../../../component/icon'
import { AppraisalSearch } from './AppraisalSearch'
import { useLazyQuery } from '@apollo/client'
import { useAppraisalData } from '../hook/appraisal-data-hook'
import { GetAppraisalForPage } from '../graphql/appraisal.graphql'
import AppraisalReport from "./AppraisalReport"

export const AppraisalPage = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [sending, setSending] = useState<boolean>(false)
  const [entity, setEntity] = useState<Appraisal>()
  const [page, setPage] = useState<number | null>()
  const [errors, setErrors] = useState<string[]>([])
  const [searchOpen, setSearchOpen] = useState<boolean>(false)
  const [findArgs, setFindArgs] = useState<AppraisalFindRequest>()
  const [entryMode, setEntryMode] = useState<EntryMode>(EntryMode.Nothing)
  const [form] = Form.useForm<AppraisalEntry>()
  const [find] = useLazyQuery<
    QueryPageableResponse<Appraisal, 'appraisalsFind'>,
    QueryPageableRequest<AppraisalFindRequest>>(GetAppraisalForPage)
  const {init, toDto, ...actions} = useAppraisalData()
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false)
  const [isRefetch, setIsRefetch] = useState<boolean>(false)

  useEffect(() => {
    setIsFormChanged(false)
  }, [entity])

  const save = async (entry: AppraisalEntry) => {
    setSending(true)

    setErrors([])
    const dto = toDto(entry)
    const id = entity?.id

    try {
      if (id) await actions.update(id, dto)
      else {
        const result = await actions.create(dto)
        if (entity) entity.id = result.id
      }

      notify.success(
        translate('appraisal'),
        translate('save_success_message')
      )

      await refresh()
    } catch (e) {
      if (e instanceof BadRequestException) setErrors(e.message)
    } finally {
      setSending(false)
      setIsRefetch(true)
    }
  }

  const remove = async () => {
    if (!entity) return

    const result = await confirm(
      translate('remove_confirmation_message', 'appraisal', '?')
    )

    if (!result) return

    await actions.delete(entity.id)

    notify.success(
      translate('remove', 'appraisal'),
      translate('the', 'appraisal', 'remove_success_message')
    )

    close()
  }

  const refresh = async () => {
    const {data} = await find({
      variables: {
        request: {
          take: 1,
          skip: 0,
          id: entity?.id
        }
      }
    })
    const [result] = data?.appraisalsFind.data ?? []
    setEntity(result)
    setEntryMode(EntryMode.Modify)
  }

  const onChange = async (request: PageableRequest, page: number): Promise<SinglePageableResponse<Appraisal>> => {
    setLoading(true)

    try {
      const {data} = await find({
        variables: {
          request: {
            take: request.take || 1,
            skip: request.skip || 0,
            ...findArgs,
          }
        }
      })

      const [entity] = data?.appraisalsFind.data ?? []

      if (!entity) {
        setEntity(undefined)
      }
      setEntity(entity)
      setEntryMode(EntryMode.Modify)

      if (!page) setPage(1)

      return {count: data?.appraisalsFind.count ?? 0}
    } finally {
      setLoading(false)
    }
  }

  const close = () => {
    setEntity(undefined)
    setEntryMode(EntryMode.Nothing)
  }

  const sendEmail = () => {
    const email = form.getFieldsValue().customerEmail

    if (!email) return

    window.open(getURL(email), '_blank')
  }

  const fetch = (findArgs: AppraisalFindRequest, page?: number) => {
    setFindArgs(findArgs)
    setPage(0)
    setTimeout(() => setPage(page), 500)
  }

  const value = useMemo<PageContextValue<any, any>>(() => ({
    form,
    save,
    entity,
    fetch,
    onFormChange: () => setIsFormChanged(true)
  }), [save, entity, fetch])

  return (
    <AppraisalPageContext.Provider
      value={value}
    >
      <Container
        leftClassName="action-container"
        paddingClass='0'
        loading={loading}
        toolbarItems={{
          start: [
            <Button
              onClick={() => setSearchOpen(true)}
              icon={<SearchOutlined/>}
              key={translate('search')}
            >
              {translate('search')}
            </Button>,

            <Button
              onClick={async () => {
                setEntryMode(EntryMode.New)
                setLoading(true)
                try {
                  setEntity(await init())
                } finally {
                  setLoading(false)
                }
              }}
              icon={<PlusOutlined/>}
              key={translate('add')}
            >
              {translate('add')}
            </Button>,

            <Button
              loading={sending}
              onClick={() => form.submit()}
              disabled={!entity}
              type={isFormChanged ? 'primary' : 'default'}
              icon={<SaveFilled/>}
              key={translate('save')}
            >
              {translate('save')}
            </Button>,

            <Button
              danger
              disabled={!(entity?.id)}
              onClick={remove}
              icon={<DeleteOutlined/>}
              key={translate('remove')}
            >
              {translate('remove')}
            </Button>,

            <Button
              disabled={entryMode === EntryMode.Nothing}
              onClick={async () => {
                if (entryMode === EntryMode.New) {
                  form.resetFields()
                  setIsFormChanged(false)
                }
                if (entryMode === EntryMode.Modify)
                  await refresh()
              }}
              icon={<StopOutlined/>}
              key={translate('clear')}
            >
              {translate('clear')}
            </Button>,

            <Button onClick={sendEmail} icon={<EnvelopIcon/>} key={translate('send', 'email')}>
              {translate('email')}
            </Button>,

            <Button
                disabled={!entity?.id}
                onClick={() => entity && window.print()}
                key={translate('report')}
                icon={<PrinterOutlined/>}
            >
              {translate('report')}
            </Button>,

            <Button onClick={close} icon={<CloseOutlined/>} key={translate('exit')}>
              {translate('exit')}
            </Button>,
          ],
          end: [
            <SinglePageable
              key={translate('pagination')}
              onChange={onChange}
              defaultPage={page}
            />,
          ],
        }}
      >
        <AppraisalSearch
          open={searchOpen}
          closeMe={() => setSearchOpen(false)}
        />

        {entity ? (
            <>
          <div className='print-hidden p-3'>
            <ErrorMessage
              title={translate('entry_fail_message', 'appraisal')}
              message={errors}
            />
            <AppraisalForm/>
          </div>
          <AppraisalReport id={entity?.id} isRefetch={isRefetch} fetched ={() => setIsRefetch(false)}/>
            </>
        ) : (
          <Empty className='mt-5'/>
        )}
      </Container>
    </AppraisalPageContext.Provider>
  )
}

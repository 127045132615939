import { translate } from "../../../core";
import { Button, Col, Divider, Form, Input, Row, Tooltip } from "antd";
import {
  Combo,
  enumToComboOptionTransformer,
  NumericInput,
  Section,
  ShowIf,
} from "../../../core/component";
import {
  stoneClarityItems,
  stoneColors,
  stoneNames,
} from "../model-mapper/item-combe-mapper";
import { StoneShape } from "../type";
import { PlusOutlined } from "@ant-design/icons";
import { CloseButton } from "react-bootstrap";

export const ItemFormJeweleryStone = () => {
  return (
    <Form.List name={["jewelery", "stones"]}>
      {(fields, { add, remove }) => (
        <>
          <Divider orientation="left" className="fw-bold my-2">
            <span>{translate("stones")}</span>

            <Button
              className="ms-3"
              type="default"
              onClick={() => add()}
              icon={<PlusOutlined />}
            >
              {translate("add", "stone")}
            </Button>
          </Divider>

          <ShowIf condition={!!fields.length} replacement={<></>}>
            <>
              {fields.map(({ name }, index) => (
                <Section noMargin key={name}>
                  <Row>
                    <Col span={21}>
                      <Row gutter={[16, 16]}>
                        <Col span={5}>
                          <Form.Item
                            label={translate("name")}
                            name={[name, "name"]}
                          >
                            <Combo size="middle" options={stoneNames} />
                          </Form.Item>
                        </Col>

                        <Col span={5}>
                          <Form.Item
                            label={translate("color")}
                            name={[name, "color"]}
                          >
                            <Combo size="middle" options={stoneColors} />
                          </Form.Item>
                        </Col>

                        <Col span={5}>
                          <Form.Item
                            label={translate("shape")}
                            name={[name, "shape"]}
                          >
                            <Combo
                              size="middle"
                              options={enumToComboOptionTransformer(StoneShape)}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={5}>
                          <Form.Item
                            label={translate("clarity")}
                            name={[name, "clarity"]}
                          >
                            <Combo size="middle" options={stoneClarityItems} />
                          </Form.Item>
                        </Col>

                        <Col span={4}>
                          <Form.Item
                            label={translate("cut")}
                            name={[name, "cut"]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={[16, 16]}>
                        <Col span={5}>
                          <Form.Item
                            style={{ marginBottom: 0 }}
                            label={translate("average_weight")}
                            name={[name, "averageWeight"]}
                          >
                            <NumericInput />
                          </Form.Item>
                        </Col>

                        <Col span={5}>
                          <Form.Item
                            label={translate("quantity")}
                            name={[name, "quantity"]}
                          >
                            <NumericInput/>
                          </Form.Item>
                        </Col>

                        <Col span={5}>
                          <Form.Item
                            label={translate("total_weight")}
                            name={[name, "totalWeight"]}
                          >
                            <NumericInput/>
                          </Form.Item>
                        </Col>

                        <Col span={5}>
                          <Form.Item
                            label={translate("cost_ct")}
                            name={[name, "costCT"]}
                          >
                            <NumericInput />
                          </Form.Item>
                        </Col>

                        <Col span={4}>
                          <Form.Item
                            label={translate("price_ct")}
                            name={[name, "totalCost"]}
                          >
                            <NumericInput />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      span={3}
                      className="d-flex flex-row justify-content-center align-items-center"
                    >
                      <Tooltip title={translate("remove")}>
                        <Button
                          icon={<CloseButton />}
                          shape="circle"
                          type="text"
                          onClick={() => remove(name)}
                        />
                      </Tooltip>
                    </Col>
                  </Row>

                  <ShowIf
                    condition={fields.length - 1 !== name}
                    replacement={<></>}
                  >
                    <Divider className="my-1" />
                  </ShowIf>
                </Section>
              ))}
            </>
          </ShowIf>
        </>
      )}
    </Form.List>
  );
};

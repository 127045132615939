import { useReport } from '../../../core/component/Report'
import { translate } from '../../../core'
import { useLazyQuery } from '@apollo/client'
import {
  GetOrderItemHistoryDocument,
  GetOrderPaymentsDocuments,
  GetSalesByCustomerDocument
} from '../graphql/order-report-graphql'
import { GetOrderPrint } from '../graphql/order-queries.graphql'
import { OrderPaymentRequest, OrderPaymentType, OrderPaymentVariables } from '../type'
import { dateDisplay, standardFormat } from '../../../core/until'

export const useOrderReport = () => {
  const [getOrderPrint] = useLazyQuery<{ orderPrint: any }, { id: number }>(GetOrderPrint)
  const [getOrderItemHistory] = useLazyQuery<{ orderItemHistory: unknown }, {
    itemId: number
  }>(GetOrderItemHistoryDocument)
  const [getSalesByCustomer] = useLazyQuery<{ salesByCustomer: unknown }, {
    request: {
      customerId: number
    }
  }>(GetSalesByCustomerDocument)
  const [getOrderPayments] = useLazyQuery<{ orderPayments: unknown }, {request: OrderPaymentVariables}>(GetOrderPaymentsDocuments)

  const {show} = useReport()

  return {
    report: {
      print: async (id: number) => {
        const {data} = await getOrderPrint({
          variables: {
            id,
          },
        })

        show({
          title: translate('print', 'order'),
          fileName: 'order-print',
          data: data?.orderPrint,
        })
      },
      itemHistory: async (itemId: number) => {
        const {data} = await getOrderItemHistory({
          variables: {
            itemId,
          },
        })

        show({
          title: translate('item', 'history'),
          fileName: 'order-item-history',
          data: data?.orderItemHistory,
        })
      },
      salesByCustomer: async (customerId: number) => {
        const {data} = await getSalesByCustomer({
          variables: {
            request: {
              customerId,
            }
          },
        })

        show({
          title: translate('sales', 'by', 'customer'),
          fileName: 'sales-by-customers',
          data: data?.salesByCustomer,
        })
      },
      payments: async (request: OrderPaymentRequest) => {
        const minDate = request.minDate.toDate()
        const maxDate = request.maxDate.toDate()

        const {data} = await getOrderPayments({
          variables: {
            request: {
              type: request.type,
              minDate: dateDisplay(minDate, standardFormat),
              maxDate: dateDisplay(maxDate, standardFormat),
            }
          }
        })

        show({
          title: request.type === OrderPaymentType.All
            ? translate('payment_type_report_title')
            : translate('credit_card_report_title'),
          fileName: 'order-payments',
          data: data?.orderPayments,
        })
      }
    },
  }
}

import { Button, Divider, Form, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { confirm, notify, translate } from '../../../core'
import { AutoComplete, ErrorMessage } from '../../../core/component'
import { useContext, useState } from 'react'
import { PageContextValue } from '../../../core/type'
import { Contact } from '../type/contact'
import { ContactEntry } from '../type/contact-entry'
import { ContactPageContext } from './ContactPageContext'
import { useMutation, useQuery } from '@apollo/client'
import { GetCityList } from '../../shared/graphql/location-queries.graphql'
import { ContactRelationEntry } from '../type/contact-relation-entry'
import {
  ContactRelationCreateMutation,
  ContactRelationRemoveMutation,
  ContactRelationUpdateMutation
} from '../graphql/contact-relation.graphql'
import { ContactRelationCreateInput } from '../type/contact-relation.dto'
import { ContactFormRelationsActions } from './ContactFormRelationsActions'
import { useNavigate } from 'react-router-dom'

export const ContactFormRelations = () => {
  const navigate = useNavigate()
  const {entity, form, refresh} = useContext<PageContextValue<Contact, ContactEntry>>(ContactPageContext)
  const {data: cityQueryResponse} = useQuery(GetCityList)
  const [create, {loading: creating}] = useMutation(ContactRelationCreateMutation, {
    errorPolicy: 'all',
  })
  const [update, {loading: updating}] = useMutation(ContactRelationUpdateMutation, {
    errorPolicy: 'all',
  })
  const [remove, {loading: removing}] = useMutation(ContactRelationRemoveMutation)
  const [errors, setErrors] = useState<string[]>([])
  const [executingIndex, setExecutingIndex] = useState<number>()

  const handleSave = async (index: number) => {
    setErrors([])
    setExecutingIndex(index)

    if (!entity)
      return setErrors([
        translate('save_parent_contact_message')
      ])

    const relation = form?.getFieldValue(['relations', index]) as ContactRelationEntry

    const input: ContactRelationCreateInput = {
      contactId: relation.contactId,
      firstName: relation.firstName,
      lastName: relation.lastName,
      city: relation.city,
      street: relation.street,
      line1: relation.line1,
      line2: relation.line2,
      phone: relation.phone
    }

    const {errors} = await (
      relation.id
        ? update({
          variables: {
            id: relation.id,
            input: {
              ...input,
              addressId: relation.addressId,
              phoneId: relation.phoneId,
            }
          }
        })
        : create({
          variables: {
            parentId: entity.id,
            input,
          },
        })
    )

    if(errors?.length)
      return setErrors(errors.map(e => e.message))

    notify.success(
      translate('contact', 'relations'),
      translate('save_success_message')
    )

    refresh!()
  }

  const handleRemove = async (index: number) => {
    setExecutingIndex(index)

    const result = await confirm(
      translate('sure_to', 'remove', 'relation', '?')
    )

    if (!result)
      return

    const id = form?.getFieldValue(['relations', index, 'id']) as number

    await remove({
      variables: {
        id,
      },
    })

    notify.success(
      translate('contact', 'relations'),
      translate('remove_relation_success_message')
    )

    refresh!()
  }

  const handleView = (index: number) => {
    const contactId = form?.getFieldValue(['relations', index, 'contactId']) as string
    navigate(`/contact/${contactId}`)
  }

  return (
    <Form.List name="relations">
      {(fields, {add, remove}) => (
        <>
          <Divider
            orientation='left'
            className='fw-bold my-1'>
            <span>
              {translate('relations')}
            </span>

            <Button
              className='ms-3'
              type='default'
              onClick={() => add()}
              icon={<PlusOutlined/>}>
              {translate('add', 'relation')}
            </Button>
          </Divider>

          <ErrorMessage
            onClose={() => setErrors([])}
            title=''
            message={errors}
          />

          {
            fields.length
              ? <table className='table table-borderless mb-1'>
                <thead>
                <tr>
                  <td className='text-center py-0' style={{width: '9%'}}>{translate('contact_id')}</td>
                  <td className='text-center py-0' style={{width: '9%'}}>{translate('first_name')}</td>
                  <td className='text-center  py-0' style={{width: '9%'}}>{translate('last_name')}</td>
                  <td className='text-center py-0' style={{width: '9%'}}>{translate('city')}</td>
                  <td className='text-center py-0' style={{width: '14%'}}>{translate('street')}</td>
                  <td className='text-center py-0' style={{width: '14%'}}>{translate('line', '1')}</td>
                  <td className='text-center  py-0' style={{width: '14%'}}>{translate('line', '2')}</td>
                  <td className='text-center  py-0' style={{width: '12%'}}>{translate('phone')}</td>
                  <td style={{width: 200}}></td>
                </tr>
                </thead>

                <tbody>
                {fields.map(({key, name}) => (
                  <tr key={key}>
                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={[name, 'contactId']}
                        rules={[{required: true, message: translate('missing_field_message', 'contact_id')}]}
                      >
                        <Input/>
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={[name, 'firstName']}
                      >
                        <Input/>
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={[name, 'lastName']}
                      >
                        <Input/>
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={[name, 'city']}
                      >
                        <AutoComplete
                          style={{width: '100%'}}
                          items={cityQueryResponse?.cityList.map(c => c.name) ?? []}
                        />
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={[name, 'street']}
                      >
                        <Input/>
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={[name, 'line1']}
                      >
                        <Input/>
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={[name, 'line2']}
                      >
                        <Input/>
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={[name, 'phone']}
                      >
                        <Input/>
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        noStyle name={[name, 'id']}
                      >
                        <ContactFormRelationsActions
                          index={name}
                          remove={() => handleRemove(name)}
                          removeLoading={name === executingIndex && removing}
                          save={() => handleSave(name)}
                          saveLoading={name === executingIndex && (creating || updating)}
                          cancel={() => remove(name)}
                          view={()=> handleView(name)}
                        />
                      </Form.Item>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
              : null
          }
        </>
      )}
    </Form.List>
  )
}
import { translate } from '../../../core'
import { InputCard } from '../../inventory/component/InputCard'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { Contact } from '../../contact/type/contact'
import { ContactFindRequest } from '../../contact/type/contact.query'
import { Button, Col, Form, Input, Row, Tooltip } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import { ContactModal } from '../../contact/component/ContactModal'
import { useState } from 'react'
import { GetContacts } from '../../contact/graphql/contact.queries.graphql'
import { ShowIf } from '../../../core/component'

export type OrderFormRelationDisplayProps = {
  customerId?: string,
}

export const OrderFormRelationDisplay = ({customerId}: OrderFormRelationDisplayProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [entity, setEntity] = useState<Contact>()
  const [findContacts] = useLazyQuery<
    QueryPageableResponse<Contact, 'contactsFind'>,
    QueryPageableRequest<ContactFindRequest>>(GetContacts)

  const {data} = useQuery<
    QueryPageableResponse<Contact, 'contactsFind'>,
    QueryPageableRequest<ContactFindRequest>>(gql`
      query GetRelationsOfContact($request: ContactFindRequest!) {
          contactsFind(request: $request) {
              data {
                  id
                  relations {
                      id
                      contactId
                      firstName
                      lastName
                      addresses {
                          city
                          street
                          line1
                          line2
                      }
                      phones {
                          number
                      }
                  }
              }
          }
      }
  `, {
    variables: {
      request: {
        contactId: customerId,
        take: 1,
        skip: 0,
      }
    }
  })

  const transform = (data: Contact[]) => {
    if (!data.length)
      return

    const relations = (data[0].relations ?? []) as Contact[]

    if (!relations.length)
      return

    const [{id, contactId, firstName, lastName, addresses, phones}] = relations

    return {
      id,
      contactId,
      firstName,
      lastName,
      city: addresses[0]?.city,
      street: addresses[0]?.street,
      line1: addresses[0]?.line1,
      line2: addresses[0]?.line2,
      phone: phones[0]?.number,
    }
  }

  const relation = transform(data?.contactsFind.data ?? [])

  return (
    <ShowIf condition={!!relation} replacement={<></>}>
      <InputCard
        title={translate('relation')}
      >
        <Row gutter={[8, 8]}>
          <Col span={3}>
            <Form.Item
              label={translate('contact_id')}
            >
              <Input
                value={relation?.contactId}
                disabled={true}
                className='input-disable-clear text-center'
              />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              label={translate('first_name')}
            >
              <Input
                value={relation?.firstName}
                disabled={true}
                className='input-disable-clear'
              />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              label={translate('last_name')}
            >
              <Input
                value={relation?.lastName}
                disabled={true}
                className='input-disable-clear'
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label={translate('street')}
            >
              <Input
                value={relation?.street}
                disabled={true}
                className='input-disable-clear'
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item
              label={translate('city')}
            >
              <Input
                value={relation?.city}
                disabled={true}
                className='input-disable-clear'
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item
              label={translate('phone')}
            >
              <Input
                value={relation?.phone}
                disabled={true}
                className='input-disable-clear'
              />
            </Form.Item>
          </Col>

          <Col span={1} className='d-flex flex-row align-items-center'>
            <Tooltip title={translate('view')}>
              <Button
                icon={<EyeOutlined/>}
                shape='circle'
                type='text'
                onClick={async () => {
                  const {data} = await findContacts({
                    variables: {
                      request: {
                        id: relation?.id,
                        take: 1,
                        skip: 0,
                      }
                    }
                  })

                  const [contact] = data?.contactsFind.data ?? []
                  setEntity(contact)
                  setOpen(true)
                }}
              />
            </Tooltip>
          </Col>
        </Row>
      </InputCard>
      <ContactModal
        open={open}
        close={() => setOpen(false)}
        onSaveCompleted={() => {
        }}
        entity={entity}
        isReadOnly
      />
    </ShowIf>
  )
}
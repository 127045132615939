import { Contact } from '../type/contact'
import { ContactEntry, ContactType } from '../type/contact-entry'
import { toMoment } from '../../../core/until'

export const contactToEntry = (entity: Contact): ContactEntry => {
  return {
    ...entity,
    contactType: resolveContactType(entity),
    dateOfBirth: toMoment(entity?.dateOfBirth),
    anniversary: toMoment(entity?.anniversary),
    relations: entity?.relations?.map(e => {
      const [address] = e.addresses
      const [phone] = e.phones
      return {
        id: e.id,
        contactId: e.contactId,
        firstName: e.firstName,
        lastName: e.lastName,
        addressId: address.id,
        phoneId: phone?.id,
        city: address?.city,
        phone: phone?.number,
        street: address?.street,
        line1: address?.line1,
        line2: address?.line2,
      }
    })
  }
}

const resolveContactType = (entity: Contact) => {
  if (entity?.isCustomer && !entity.isVendor)
    return ContactType.Customer

  if (entity?.isVendor && !entity.isCustomer)
    return ContactType.Vendor

  return ContactType.Customer
}

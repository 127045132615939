import { DataGridItem } from '../../../core/component'
import { PageableRequest } from '../../../core/type'

export enum JobStatus {
  Completed = 'Completed',
  Failed = 'Failed',
  Pending = 'Pending'
}

export interface Job {
  id: string
  status: JobStatus
  createdAt: Date
  updatedAt: Date
  attemptsMade: number
  input: any
  returnValue: any
  failedReason: any
}

export interface JobGrid extends Job, DataGridItem {
}

export type JobPageableRequest = PageableRequest<{ status?: JobStatus[], name: string }>

export type JobRetryDto = {
  id: number
  name: string
}

export type JobMetaData = {
  name: string
  alias: string
}

import { FormEntryField, QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { Combo } from '../../../core/component'
import { useLazyQuery } from '@apollo/client'
import { WeddingRegistry, WeddingRegistryFindRequest } from '../../wedding-registry/type'
import { GetWeddingRegistriesForLookup } from '../../wedding-registry/graphql/wedding-registry-queries'

type FormProps = FormEntryField<number> & {
  onSelect: (entity: WeddingRegistry) => void
}

export const OrderFormWeddingRegistrySelect = ({value, onChange, onSelect}: FormProps) => {
  const [find] = useLazyQuery<QueryPageableResponse<WeddingRegistry, 'weddingRegistryFind'>,
    QueryPageableRequest<WeddingRegistryFindRequest>>(GetWeddingRegistriesForLookup)

  return (
    <Combo<WeddingRegistry, number>
      value={value}
      size="middle"
      fetch={async ({request, value, search}) => {
        const {data} = await find({
          variables: {
            request: {
              take: request?.take ?? 100,
              skip: request?.skip ?? 0,
              id: value,
              number: search ? Number(search) : undefined,
            },
          },
        })

        const list = data?.weddingRegistryFind.data ?? []

        return list.map((e) => ({
          value: e.id || 0,
          display: e.number.toString(),
          item: e || {},
        }))
      }}
      onChange={(id) => onChange!(id as number)}
      onSelect={(_, entity) => {
        onSelect(entity)
      }}
      onBound={(entities) => {
        const [entity] = entities as WeddingRegistry[]
        onSelect(entity)
      }}
    />
  )
}


import type { DraggableData, DraggableEvent } from 'react-draggable'
import Draggable from 'react-draggable'
import React, { FC, useRef, useState } from 'react'
import { Modal, ModalProps } from 'antd'

export const DraggableModal: FC<ModalProps & { children: React.ReactNode }> = ({children, ...props}) => {
  const draggableRef = useRef<HTMLDivElement>(null)
  const [bounds, setBounds] = useState({left: 0, top: 0, bottom: 0, right: 0})
  const [disabled, setDisabled] = useState(true)

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const {clientWidth, clientHeight} = window.document.documentElement
    const targetRect = draggableRef.current?.getBoundingClientRect()
    if (!targetRect) {
      return
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    })
  }

  return (
    <Modal
      {...props}
      title={
        <div
          style={{ width: '100%', cursor: 'move' }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false)
            }
          }}
          onMouseOut={() => {
            setDisabled(true)
          }}
        >
          {props.title}
        </div>
      }
      modalRender={(modal)=> (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          nodeRef={draggableRef}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggableRef}>{modal}</div>
        </Draggable>
      )}
    >
      {children}
    </Modal>
  )
}
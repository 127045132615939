import Enumerable from 'linq'
import { useContext, useEffect, useState } from 'react'
import { PageContextValue } from '../../../core/type'
import { Appraisal, AppraisalEntry } from '../type'
import { AppraisalPageContext } from './AppraisalPageContext'
import { Col, DatePicker, Form, Input, Row, Space } from 'antd'
import { CopyToClipboard, Panel, Section } from '../../../core/component'
import { translate } from '../../../core'
import { dateFormat } from '../../../core/until'
import { AppraisalFormUser } from './AppraisalFormUser'
import { AppraisalFormAddress } from './AppraisalFormAddress'
import { AppraisalFormLines } from './AppraisalFormLines'
import styles from './style.module.scss'
import { useAppraisalData } from '../hook/appraisal-data-hook'
import { ContactSelect } from '../../contact/component/ContactSelect'
import { Contact, ContactWebInfo, WebInfoType } from '../../contact/type/contact'

export const AppraisalForm = () => {
  const { toEntry } = useAppraisalData()
  const { form, save, entity, onFormChange } =
    useContext<PageContextValue<Appraisal, AppraisalEntry>>(AppraisalPageContext)
  const [customerId, setCustomerId] = useState<string>('')

  useEffect(() => {
    entity && form?.setFieldsValue(
      toEntry(entity) as any
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ entity ])

  const onCustomerSelectedOrBound = (contact: Contact, isBound: boolean) => {
    setCustomerId(contact?.contactId)

    if (isBound)
      return

    const address = Enumerable.from(contact.addresses || []).firstOrDefault(e => !!e)

    form?.setFieldsValue({
      customerFirstname: contact.firstName,
      customerLastname: contact.lastName,
      customerNumber: Enumerable.from(contact.phones || []).firstOrDefault()?.number,
      customerAddress: {
        street: address?.street,
        country: address?.country,
        city: address?.city,
        state: address?.state,
        zipCode: address?.zipCode
      },
      customerEmail: Enumerable.from(contact.webInfos || [])
        .firstOrDefault(e => e.type === WebInfoType.EmailAddress, {} as ContactWebInfo)
        ?.value
    })
  }

  const onLinesChanged = () => {
    const lines = form?.getFieldValue('lines')
    form?.setFieldValue('numberOfItems', lines.length)
  }

  return (
    <div>
      <Form
        onFieldsChange={onFormChange}
        form={ form }
        onFinish={ save }
        style={ { maxWidth: '930px' } }
      >
      <div>
        <Row style={ { height: '40px' } }>
              <Col span={ 6 } className={ styles['header'] }>
                <Form.Item name='number' label={ translate('Appraisal_no') }>
                  <Input className='input-disable-clear' disabled={ true }/>
                </Form.Item>
              </Col>

              <Col span={ 7 } className={ styles['header'] }>
                <Form.Item
                  label={ <Space>
                    {translate('customer_no')}
                    <CopyToClipboard
                      text={customerId}
                      tooltip={translate('copy', 'contact_id')}
                    />
                  </Space> }
                  name={ [ 'customer', 'id' ] }
                >
                  <ContactSelect
                    onCustomerChangedOrBound={ onCustomerSelectedOrBound }
                  />
                </Form.Item>
              </Col>

              <Col span={ 5 } className={ styles['header'] }>
                <Form.Item
                  label={ translate('date') }
                  name='date'
                  rules={ [
                    {
                      required: true,
                      message: translate('missing_field_message', 'date'),
                    },
                  ] }
                >
                  <DatePicker
                    format={ dateFormat }
                    className={ styles['dataPicker'] }
                  />
                </Form.Item>
              </Col>

              <Col span={ 6 } style={ { display: 'flex', justifyContent: 'flex-end' } }>
                <Form.Item name='appraiserId' label={ translate('appraiser') }>
                  <AppraisalFormUser/>
                </Form.Item>
              </Col>
            </Row>
        </div>
        <Panel className='w-100  p-0'>
        <div className='rounded'>
          <Section header={ translate('customer', 'info') } noMargin>


            <Row style={ { height: '40px' } }>
              <Col span={ 7 } className={ styles['wrapper'] }>
                <Form.Item
                  name='customerFirstname'
                  label={ translate('first_name') }
                  labelCol={ { span: 8 } }
                  labelAlign='left'
                >
                  <Input/>
                </Form.Item>
              </Col>
              <Col span={ 7 } className={ styles['wrapper'] }>
                <Form.Item
                  name='customerLastname'
                  label={ translate('last_name') }
                  labelCol={ { span: 8 } }
                  labelAlign='left'
                >
                  <Input/>
                </Form.Item>
              </Col>
            </Row>

            <AppraisalFormAddress/>

            <Row style={ { height: '40px' } }>
            <Col span={ 7 } className={ styles['wrapper'] }>
                <Form.Item
                  label={ translate('email') }
                  name='customerEmail'
                  rules={ [
                    {
                      type: 'email',
                      message: translate('email_invalid_message'),
                    },
                  ] }
                  labelCol={ { span: 8 } }
                  labelAlign='left'
                >
                  <Input/>
                </Form.Item>
              </Col>

              <Col span={ 7 } className={ styles['wrapper'] }>
                <Form.Item name='customerNumber' label={ translate('phone') } labelCol={ { span: 8 } }
                           labelAlign='left'>
                  <Input/>
                </Form.Item>
              </Col>

              <Col span={ 7 } className={ styles['wrapper'] }>
                <Form.Item
                  label={ translate('number') }
                  name='numberOfItems'
                  labelCol={ { span: 8 } }
                  labelAlign='left'
                >
                  <Input disabled={ true }/>
                </Form.Item>
              </Col>
            </Row>
          </Section>
        </div>
        </Panel>

        <Panel className='w-100  p-0 mt-1'>
        <Section header={ translate('items') } noMargin>
          <AppraisalFormLines
            onChanged={ onLinesChanged }
          />
        </Section>
        </Panel>
      </Form>
    </div>
  )
}

import { Outlet } from 'react-router-dom'
import React, { useEffect, useState, lazy } from 'react'
import { Layout, Space } from 'antd'
import { SideMenu } from '../protectedLayout/SideMenu'
import { ToggleMenuButton } from '../protectedLayout/ToggleMenuButton'
import { useCheckAuthentication } from '../../../auth/hook/user-authentication.hook'
import styles from './Layout.module.scss'

const ReportComponent = lazy(async () => import('../../../../core/component/Report/ReportComponent'))

const {Sider, Content} = Layout

export const ProtectedLayout: React.FC = () => {
  const checkAsync = useCheckAuthentication()
  const [sideBarEnabled, setSideBarEnabled] = useState<boolean>(true)


  useEffect(() => {
    checkAsync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout className={styles.protectedContainer}>
      <Sider className={`
      ${styles.siderContainer} 
      ${sideBarEnabled ? '' : 'd-none'}
      `}
             width={250}>
        <SideMenu/>
      </Sider>
      <Layout className='bg-gray'>
        <div className={'bg-gray d-flex p-2 d-flex flex-row' + styles.headerContainer}>
          <div className='w-100 d-flex flex-row'>
            <ToggleMenuButton onClick={() => setSideBarEnabled(!sideBarEnabled)}/>
          </div>
          <Space className='justify-content-end w-100' size='large'>

          </Space>
        </div>
        <Content className={'px-2 ' + styles.protectedContent}>
          <ReportComponent/>
          <Outlet/>
        </Content>
      </Layout>
    </Layout>
  )
}


import { DataGridItem } from '../../../core/component'

export enum PrinterTemplateType {
  Repair = 'Repair',
  Tableware_Giftware = 'Tableware_Giftware',
  Jewelry = 'Jewelry',
}

export interface Printer {
  id: number
  title: string
  ip: string
  port: number
  templateType: PrinterTemplateType
}

export interface PrinterEntry extends Printer, DataGridItem {
}

export interface PrinterDto extends Omit<Printer, 'id'> {
}

export interface PrinterPintDto {
  templateType: PrinterTemplateType
  data: string
}

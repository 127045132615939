import React, { FC, useEffect } from 'react'
import { translate } from '../../../core'
import { Button, Checkbox, Form, Input, Space } from 'antd'
import { Combo, Container } from '../../../core/component'
import { InventoryByPatternRequest, ItemType } from '../type'
import { getItemTypeComboOptions } from '../func/type-options'
import { Contact } from '../../contact/type/contact'
import { stringConcat } from '../../../core/until'
import { useItemReport } from '../hook/item-report.hook'
import { useLazyQuery } from '@apollo/client'
import { QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { ContactFindRequest } from '../../contact/type/contact.query'
import { GetVendorsForLookup } from '../../contact/graphql/contact.queries.graphql'
import { ItemSearchCategory } from './ItemSearchCategory'
import { InputCard } from './InputCard'

type InventoryByPatternRequestForm =
  Omit<InventoryByPatternRequest, 'categoryId' | 'subcategoryId' | 'subcategory2Id'>
  & {
  category?: Pick<InventoryByPatternRequest, 'categoryId' | 'subcategoryId' | 'subcategory2Id'>
}

export const InventoryByPatternReport: FC = () => {
  const [form] = Form.useForm<InventoryByPatternRequestForm>()
  const {report} = useItemReport()
  const [findContacts] = useLazyQuery<
    QueryPageableResponse<Contact, 'contactsFind'>,
    QueryPageableRequest<ContactFindRequest>>(GetVendorsForLookup)

  const handleSubmit = (data: InventoryByPatternRequestForm) => {
    const {category} = data
    delete data.category
    return report.inventoryByPattern({
      ...data,
      categoryId: category?.categoryId ?? [],
      subcategoryId: category?.subcategoryId ?? [],
      subcategory2Id: category?.subcategory2Id ?? [],
    })
  }

  useEffect(() => {
    form.setFieldsValue({
      inStock: true
    })
  }, [])

  return (
    <Container
      leftClassName='action-container'
      paddingClass='0'
    >
      <InputCard className='content-size'>
        <Form
          layout='horizontal'
          form={form}
          onFinish={handleSubmit}
          className='d-flex flex-column gap-2 w-50'
        >
          <Form.Item
            name='types'
            label={translate('type')}
          >
            <Combo<any, ItemType>
              allowMultiple
              size='middle'
              options={getItemTypeComboOptions()}
            />
          </Form.Item>

          <Form.Item
            label={translate('vendor')}
            name='vendorId'
          >
            <Combo<Contact, number>
              size='middle'
              fetch={async ({request, value, search}) => {
                const {data} = await findContacts({
                  variables: {
                    request: {
                      ...request,
                      take: request?.take ?? 100,
                      skip: request?.skip ?? 0,
                      searchVendor: search,
                      id: value
                    }
                  }
                })

                const list = data?.contactsFind.data ?? []

                return list.map((e) => ({
                  value: e.id || 0,
                  display: stringConcat(e.contactId, ' - ', e.company),
                  item: e || {},
                }))
              }}
            />
          </Form.Item>

          <Form.Item
            name='style'
            label={translate('style', '/', 'pattern')}
          >
            <Input/>
          </Form.Item>

          <Form.Item
            name='category'
            label={translate('category')}
          >
            <ItemSearchCategory/>
          </Form.Item>

          <Form.Item name='inStock' valuePropName='checked'>
            <Checkbox>{translate('in_stock_only')}</Checkbox>
          </Form.Item>

          <Form.Item name='showPictures' valuePropName='checked'>
            <Checkbox>{translate('show_pictures')}</Checkbox>
          </Form.Item>

          <Space className='gap-1'>
            <Button
              onClick={() => form.resetFields()}
            >
              {translate('clear')}
            </Button>
            <Button
              type='primary'
              onClick={form.submit}
            >
              {translate('print')}
            </Button>
          </Space>
        </Form>
      </InputCard>
    </Container>
  )
}



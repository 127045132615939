import React, { FC, useEffect, useState } from 'react'
import { translate } from '../../../core'
import { Button, Checkbox, Form, Input, Modal, Space } from 'antd'
import { InventoryByManufactureRequest, ItemType } from '../type'
import { useItemReport } from '../hook/item-report.hook'
import { Combo, Container } from '../../../core/component'
import { getItemTypeComboOptions } from '../func/type-options'
import { InputCard } from './InputCard'

export const InventoryByManufactureReport: FC = () => {
  const [form] = Form.useForm<InventoryByManufactureRequest>()
  const {report} = useItemReport()

  const handleSubmit = (data: InventoryByManufactureRequest) => {
    return report.inventoryByManufactureWatch(data)
  }

  useEffect(() => {
    form.setFieldsValue({
      inStock: true
    })
  }, [])

  return (
    <Container
      leftClassName='action-container'
      paddingClass='0'
    >
      <InputCard className='content-size'>
        <Form
          layout='horizontal'
          form={form}
          onFinish={handleSubmit}
          className='d-flex flex-column gap-2 w-50'
        >
          <Form.Item
            name='type'
            label={translate('type')}
          >
            <Combo<any, ItemType>
              allowMultiple
              size="middle"
              options={getItemTypeComboOptions()}
            />
          </Form.Item>

          <div>
            {translate('manufacture', 'or', 'vendor')} :
          </div>

          <Space className='w-100'>
            <Form.Item
              name='vendorContactIdFrom'
              label={translate('from')}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              name='vendorContactIdTo'
              label={translate('to')}
            >
              <Input/>
            </Form.Item>
          </Space>

          <Form.Item name='inStock' valuePropName='checked'>
            <Checkbox>{translate('in_stock_only')}</Checkbox>
          </Form.Item>

          <Space className='gap-1'>
            <Button
              onClick={() => form.resetFields()}
            >
              {translate('clear')}
            </Button>
            <Button
              type='primary'
              onClick={form.submit}
            >
              {translate('print')}
            </Button>
          </Space>
        </Form>
      </InputCard>
    </Container>
  )
}

const useInventoryByManufacture = () => {
  const [open, setOpen] = useState<boolean>(false)
  const {report} = useItemReport()
  const [form] = Form.useForm<InventoryByManufactureRequest>()

  const handleOK = (data: InventoryByManufactureRequest) => {
    setOpen(false)
    return report.inventoryByManufactureWatch(data)
  }

  return {
    component: <>
      <Modal
        maskClosable={false}
        title={translate('inventory', 'by', 'manufacture')}
        closable={true}
        open={open}
        onCancel={() => setOpen(false)}
        width='30%'
        footer={[
          <Button
            onClick={() => form.resetFields()}
          >
            {translate('clear')}
          </Button>,
          <Button
            type='primary'
            onClick={form.submit}
          >
            {translate('ok')}
          </Button>,
        ]}
      >
        <Form
          layout='horizontal'
          form={form}
          onFinish={handleOK}
        >
          <Form.Item
            name='type'
            label={translate('type')}
          >
            <Combo<any, ItemType>
              allowMultiple
              size="middle"
              options={getItemTypeComboOptions()}
            />
          </Form.Item>

          <Space className='w-100'>
            <span>
              {translate('manufacture', 'or', 'vendor')}
            </span>

            <Form.Item
              name='vendorContactIdFrom'
              label={translate('from')}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              name='vendorContactIdTo'
              label={translate('to')}
            >
              <Input/>
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    </>,
    show: () => setOpen(true),
  }
}



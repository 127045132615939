import { useReport } from '../../../core/component/Report'
import { translate } from '../../../core'
import { useLazyQuery } from '@apollo/client'
import {
  InventoryByManufactureRequest,
  InventoryByManufactureWatchResponse,
  InventoryByPatternReportResponse,
  InventoryByPatternRequest
} from '../type'
import {
  GetInventoryByManufactureWatchDocuments,
  GetInventoryByPatternGqlDocuments,
  GetStoneInventoryManagementDocuments
} from '../graphql/itemReport.graphql'
import { StoneInventoryManagementArgs } from '../../reports/sales-by-vendor/type/stone-inventory-management'

export const useItemReport = () => {
  const [getInventoryByPatternData] =
    useLazyQuery<{ inventoryByPattern: InventoryByPatternReportResponse[] }, {
      request: InventoryByPatternRequest
    }>(GetInventoryByPatternGqlDocuments)
  const [getInventoryByManufacture] =
    useLazyQuery<{ inventoryByManufacture: InventoryByManufactureWatchResponse[] }, {
      request: InventoryByManufactureRequest
    }>(GetInventoryByManufactureWatchDocuments)
  const [getStoneInventoryManagement] =
    useLazyQuery<{ stoneInventoryManagement: any }, {
      request: StoneInventoryManagementArgs
    }>(GetStoneInventoryManagementDocuments)
  const {show} = useReport()

  return {
    report: {
      inventoryByPattern: async (request: InventoryByPatternRequest) => {
        const {data} = await getInventoryByPatternData({
          variables: {
            request
          }
        })

        show({
          title: translate('inventory', 'by', 'pattern'),
          data: data?.inventoryByPattern,
          fileName: 'inventory-by-pattern',
        })
      },
      inventoryByManufactureWatch: async (args: InventoryByManufactureRequest) => {
        const {data} = await getInventoryByManufacture({
          variables: {
            request: args,
          }
        })

        show({
          title: translate('inventory', 'by', 'manufacture'),
          data: data?.inventoryByManufacture,
          fileName: 'inventory-by-manufacture',
        })
      },
      stoneInventoryManagement: async (args: StoneInventoryManagementArgs) => {
        const {data} = await getStoneInventoryManagement({
          variables: {
            request: args,
          },
        })

        show({
          title: translate('stone', 'inventory', 'management', 'report'),
          data: data?.stoneInventoryManagement,
          fileName: 'stone-inventory-management',
        })
      },
    },
  }
}

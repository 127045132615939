import { translate } from '../../../core';
import { Col, Divider, Form, Input, Row } from 'antd';
import { Combo, enumToComboOptionTransformer, Section } from '../../../core/component';
import { Sex, WatchMovement, WatchShape } from '../type';
import styles from "./ItemForm.module.scss"

export const ItemFormWatch = () => {
  return (
    <Section noMargin>
      <Divider
        orientation='left'
        className='fw-bold mt-0 mb-1'
      >
        { translate('watch') }
      </Divider>
      <Row gutter={ [ 16, 16 ] }>
        <Col span={ 12 }>
          <Form.Item
            className={styles["types-first-input"]}
            label={ translate('name') }
            name={ [ 'watch', 'name' ] }
          >
            <Input/>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={ [ 16, 16 ] }>
        <Col span={ 8 }>
          <Form.Item
            className={styles["types-first-input"]}
            label={ translate('lady', '/', 'gentleman') }
            name={ [ 'watch', 'type' ] }
          >
            <Combo
              size='middle'
              options={ enumToComboOptionTransformer(Sex) }
            />
          </Form.Item>
        </Col>

        <Col span={ 8 }>
          <Form.Item
            label={ translate('movement') }
            name={ [ 'watch', 'movement' ] }
          >
            <Combo
              size='middle'
              options={enumToComboOptionTransformer(WatchMovement)}
            />
          </Form.Item>
        </Col>

        <Col span={ 8 }>
          <Form.Item
            label={ translate('shape') }
            name={ [ 'watch', 'shape' ] }
          >
            <Combo
              size='middle'
              options={ enumToComboOptionTransformer(WatchShape) }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={ [ 16, 16 ] }>
        <Col span={ 8 }>
          <Form.Item
            className={styles["types-first-input"]}
            label={ translate('dial') }
            name={ [ 'watch', 'dial' ] }
          >
            <Combo
              size='middle'
            />
          </Form.Item>
        </Col>

        <Col span={ 8 }>
          <Form.Item
            label={ translate('band') }
            name={ [ 'watch', 'band' ] }
          >
            <Input/>
          </Form.Item>
        </Col>

        <Col span={ 8 }>
          <Form.Item
            label={ translate('material') }
            name={ [ 'watch', 'material' ] }
          >
            <Input/>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={ [ 16, 16 ] }>
        <Col span={ 12 }>
          <Form.Item
            className={styles["types-first-input"]}
            label={ translate('face_color') }
            name={ [ 'watch', 'forceColor' ] }
          >
            <Input/>
          </Form.Item>
        </Col>

        <Col span={ 12 }>
          <Form.Item
            label={ translate('case_color') }
            name={ [ 'watch', 'colorCase' ] }
          >
            <Input/>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={ [ 16, 16 ] }>
        <Col span={ 12 }>
          <Form.Item
            className={styles["types-first-input"]}
            label={ translate('case_number_abbr') }
            name={ [ 'watch', 'caseNumber' ] }
          >
            <Input/>
          </Form.Item>
        </Col>

        <Col span={ 12 }>
          <Form.Item
            label={ translate('serial_number_abbr') }
            name={ [ 'watch', 'serialNumber' ] }
          >
            <Input/>
          </Form.Item>
        </Col>
      </Row>
    </Section>
  )
}

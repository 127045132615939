import { gql, TypedDocumentNode } from '@apollo/client'
import { QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { InventoryQuantityHistory, InventoryQuantityHistoryFindRequest } from '../type/inventory-quantity-history'

export const InventoryQuantityHistoryQuery: TypedDocumentNode<
  QueryPageableResponse<InventoryQuantityHistory, 'inventoryQuantityHistoryFind'>,
  QueryPageableRequest<InventoryQuantityHistoryFindRequest>> = gql`
    query InventoryQuantityHistoryQuery($request: InventoryQuantityHistoryFindRequest) {
        inventoryQuantityHistoryFind(request: $request) {
            data {
                id
                createdAt
                quantity
                description
            }
            count
        }
    }
`
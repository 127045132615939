import React, { useRef, useState } from 'react'
import {
  DataGrid,
  DataGridEditableCell,
  DataGridRef,
  editableActions,
  editableColumn,
  useDataGridEditable,
} from '../../../core/component'
import { confirm, notify, translate } from '../../../core'
import { Button, Form, Input, Space, Tooltip } from 'antd'
import { DATA_GRID_NEW_KEY } from '../../../core/component/DateGrid/resolver'
import { BadRequestException, QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { PlusOutlined } from '@ant-design/icons'
import { contactGroupService } from '../service'
import { ContactGroup, ContactGroupEntry } from '../type/contact-group'
import { useLazyQuery } from '@apollo/client'
import { contactGroupEntityToEntry, contactGroupEntryToDto } from '../model-mapper/contact-group-mapper'
import { GetContactGroups } from '../graphql/contact-group-queries.graphql'
import { ContactGroupFindRequest } from '../type/contact-group.query'

export const ContactGroupList = () => {
  const dataGridRef = useRef<DataGridRef<ContactGroupEntry>>(null)
  const [saving, setSaving] = useState<boolean>(false)
  const [find] = useLazyQuery<
    QueryPageableResponse<ContactGroup, 'contactGroupsFind'>,
    QueryPageableRequest<ContactGroupFindRequest>>(GetContactGroups)

  const onSave = async (key: React.Key, row: ContactGroupEntry) => {
    setSaving(true)
    const dto = contactGroupEntryToDto(row)

    try {
      if (key === DATA_GRID_NEW_KEY)
        await contactGroupService.create(dto)
      else
        await contactGroupService.update(row.id, dto)

      notify.success(
        translate('contact_group'),
        translate('the', 'contact_group', 'save_success_message'))

      dataGridRef.current?.refresh()
      markAsCompleted()
    } catch (e) {
      if (e instanceof BadRequestException)
        notify.error(
          translate('wrong_message', 'contact_group'),
          [e.message].flat().join('<br/>'),
        )
    } finally {
      setSaving(false)
    }
  }

  const handleRemove = async (entity: ContactGroupEntry) => {
    const result = await confirm(
      translate('remove_confirmation_message', 'contact_group', '?'),
    )

    if (!result)
      return

    await contactGroupService.remove(entity.id)

    notify.success(
      translate('remove', 'contact_group'),
      translate('contact_group', 'remove_success_message'),
    )

    dataGridRef.current?.refresh()
  }

  const {
    form,
    editingKey,
    isEditing,
    cancel,
    edit,
    save,
    markAsCompleted,
  } = useDataGridEditable<ContactGroupEntry>(dataGridRef, onSave)

  return (
    <div className="p-2">
      <Space className="w-100 justify-content-end p-2">
        <Tooltip title={translate('add')}>
          <Button
            icon={<PlusOutlined/>}
            onClick={() => {
              const row = dataGridRef.current?.addRow()
              edit(row ?? {})
            }}>
          </Button>
        </Tooltip>
      </Space>


      <Form form={form} component={false}>
        <DataGrid<ContactGroupEntry>
          ref={dataGridRef}
          columns={[
            {
              dataIndex: 'id',
              title: translate('id'),
              width: 200,
            },
            editableColumn({
              dataIndex: 'title',
              title: translate('title'),
              editableComponent: <Form.Item name="title" rules={[
                {
                  required: true,
                  message: translate('missing_field_message', 'title'),
                },
              ]}>
                <Input/>
              </Form.Item>,
              isEditing,
            }),
            editableActions(
              editingKey,
              saving,
              isEditing,
              cancel,
              edit,
              save,
              handleRemove,
            ),
          ]}
          fetch={async (request) => {
            const {data} = await find({
              variables: {
                request,
              },
            })

            const list = data?.contactGroupsFind.data ?? []
            const count = data?.contactGroupsFind.count ?? 0

            return {
              data: list.map(contactGroupEntityToEntry),
              count,
            }
          }}
          components={{
            body: {
              cell: DataGridEditableCell,
            },
          }}
        />
      </Form>
    </div>
  )
}

import { FormInstance } from 'antd'
import { CSSProperties } from 'react'

export interface CompletionProps {
  onCompleted(): void
}

export interface SetErrorMessageProps {
  setErrorMessage(message: string): void
}

export interface IconProps {
  size?: number
  style?: CSSProperties
}

export interface PageContextValue<TEntity, TEntry> {
  entity?: TEntity | undefined
  save?: (data: TEntry) => Promise<void>
  form?: FormInstance<TEntry>
  fetch?: (search: any, page?: number) => void
  refresh?: () => void
  onFormChange?: () => void
  isFormChanged?: boolean
}

export interface FormEntryField<TValue> {
  value?: TValue | undefined | null
  onChange?: (entry: TValue | undefined) => void
  checked?: TValue | undefined
}

export enum EntryMode {
  Nothing = 'Nothing',
  New = 'New',
  Modify = 'Modify'
}


import React, { FC, useState } from 'react'
import { FormEntryField, QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { useQuery } from '@apollo/client'
import { ItemCategory, ItemCategoryFindRequest, ItemCategoryLevel } from '../type'
import { GetItemCategoriesForLookup } from '../graphql/item-category.graphql'
import { Combo, ComboOption } from '../../../core/component'
import Enumerable from 'linq'

export const ItemSearchCategorySingle: FC<FormEntryField<string> & {
  level: ItemCategoryLevel,
  disabled: boolean
}> = ({value, onChange, disabled, level}) => {
  const [search, setSearch] = useState<string>('')
  const {data, loading} = useQuery<
    QueryPageableResponse<ItemCategory, 'itemCategoriesFind'>,
    QueryPageableRequest<ItemCategoryFindRequest>>(GetItemCategoriesForLookup, {
    variables: {
      request: {
        take: 20,
        skip: 0,
        level,
        title: search,
      },
    },
  })

  const mapToOptions = (data?: QueryPageableResponse<ItemCategory, 'itemCategoriesFind'>): ComboOption<ItemCategory>[] =>
    Enumerable.from((data?.itemCategoriesFind.data ?? []))
      .distinct(e => e.title?.trim())
      .select(e => ({
        value: e.title ?? '',
        display: e.title ?? '',
        item: e,
      }))
      .toArray()

  return <Combo<ItemCategory, string>
    disabled={disabled}
    loading={loading}
    value={value}
    size='middle'
    className='w-100'
    options={mapToOptions(data)}
    onChange={value => onChange!([value].flat()[0])}
    onSearch={setSearch}
  />
}
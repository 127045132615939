import { gql, TypedDocumentNode } from '@apollo/client'
import { QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { Contact } from '../type/contact'
import { ContactFindRequest } from '../type/contact.query'

export const GetContacts: TypedDocumentNode<
  QueryPageableResponse<Contact, 'contactsFind'>,
  QueryPageableRequest<ContactFindRequest>> = gql`
    query GetContacts($request: ContactFindRequest!) {
        contactsFind(request: $request) {
            data {
                id
                contactId
                gender
                isCustomer
                isVendor
                allowContact
                noEmailMarketing
                designer
                company
                firstName
                lastName
                note
                dateOfBirth
                anniversary
                accountNumber
                markupNumber
                promotion
                category {
                    id
                    title
                },
                group {
                    id
                    title
                },
                phones {
                    type
                    countryCode
                    number
                    extension
                },
                addresses {
                    type
                    country
                    state
                    city
                    street
                    zipCode
                    line1
                    line2
                },
                webInfos {
                    type
                    value
                },
                relations {
                    id
                    contactId
                    firstName
                    lastName
                    addresses {
                        id
                        city
                        street
                        line1
                        line2
                    },
                    phones {
                        id
                        number
                    }
                }
            }
            count
        }
    }
`

export const GetContactsToLookForGroups = gql`
    query GetContactsToLookForGroups($request: ContactFindRequest!) {
        contactsFind(request: $request){
            data {
                group {
                    id
                }
            }
        }
    }
`

export const GetVendorsForLookup = gql`
    query GetContactsForLookup($request: ContactFindRequest!) {
        contactsFind(request: $request) {
            data {
                id
                contactId
                company
            }
        }
    }
`

export const GetContactPage: TypedDocumentNode<{ contactPage: number }, { contactId: string }> = gql`
    query GetPage($contactId: String!) {
        contactPage(contactId: $contactId)
    }
`

export const GetSalesByCustomer = gql`
    query salesByCustomer ($request: OrderSalesByCustomerRequest!) {
        salesByCustomer (request: $request)
    }
`;

import { Form, Row } from "antd";
import { translate } from "../../../core";
import React from "react";
import styles from "./Order.module.scss";
import { InputCard } from "../../inventory/component/InputCard";
import { NumericInput } from '../../../core/component'

export const OrderEntryFooter = ({ onChanged }: { onChanged?: () => void }) => {
  return (
    <InputCard
      title={ translate("amounts") }
      className={ styles["amountsWrapper"] }
    >
      <Row
        align="middle"
        justify="space-between"
        className={ styles["amountsCard"] }
      >
        <TotalAmountSection
          name="totalPrice"
          label={ translate("total_price") }
          readOnly
        />

        <TotalAmountSection name="tax" label={ translate("tax") } readOnly/>

        <TotalAmountSection
          name="freight"
          onChanged={ onChanged }
          label={ translate("freight") }
        />

        <TotalAmountSection name="total" label={ translate("total") } readOnly/>

        <TotalAmountSection
          name="totalPay"
          label={ translate("total_payments_abbr") }
          readOnly
        />

        <TotalAmountSection
          name="balanceDue"
          label={ translate("balance") }
          readOnly
        />
      </Row>
    </InputCard>
  );
};

const TotalAmountSection = ({
                              label,
                              name,
                              readOnly,
                              onChanged,
                            }: {
  label: React.ReactNode;
  name: string;
  readOnly?: boolean;
  onChanged?: () => void;
}) => (
  <Form.Item name={ name } label={ label }>
    <NumericInput
      style={ { width: 100 } }
      className={ `${ readOnly ? styles.readOnlyInput : "" } ${
        styles.amountInput
      }` }
      onChange={ onChanged }
      disabled={ readOnly ?? false }
    />
  </Form.Item>
);

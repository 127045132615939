import { ContactEntry, ContactType } from '../type/contact-entry'
import { ContactDto } from '../type/contact-dto'

export const contactEntryToDto = (entry: ContactEntry): ContactDto => {
  entry.phones = entry.phones || []
  entry.addresses = entry.addresses || []
  entry.webInfos = entry.webInfos || []
  entry.relations = entry.relations || []

  return {
    gender: entry.isBride ? 'Male' : 'Female',
    isCustomer: entry.contactType === ContactType.Customer,
    isVendor: entry.contactType === ContactType.Vendor,
    noEmailMarketing: !!entry.noEmailMarketing,
    allowContact: !!entry.allowContact,
    contactId: entry.contactId,
    company: entry.company,
    designer: entry.designer,
    firstName: entry.firstName,
    lastName: entry.lastName,
    note: entry.note,
    dateOfBirth: entry.dateOfBirth,
    anniversary: entry.anniversary,
    accountNumber: entry.accountNumber,
    markupNumber: entry.markupNumber,
    promotion: entry.promotion,
    percentOff: entry.percentOff,
    groupId: entry.group ? entry.group.id : null,
    categoryId: entry.category ? entry.category.id : null,
    phones: entry.phones.map(ph => ({
      id: ph.id,
      type: ph.type,
      countryCode: ph.countryCode,
      number: ph.number,
      extension: ph.extension,
    })),
    addresses: entry.addresses.map(ad => ({
      id: ad.id,
      type: ad.type,
      country: ad.country,
      state: ad.state,
      city: ad.city,
      street: ad.street,
      zipCode: ad.zipCode,
      line1: ad.line1,
      line2: ad.line2,
    })),
    webInfos: entry.webInfos.map((e) => ({
      type: e.type,
      value: e.value,
    })),
  }
}

import { Button, Divider, Form, Input, Space, Tooltip } from "antd"
import { translate } from "../../../core"
import { EyeOutlined, PlusOutlined } from "@ant-design/icons"
import { CloseButton } from "react-bootstrap"
import { Item, ItemEntry } from "../type"
import { useContext } from "react"
import { PageContextValue, QueryPageableRequest, QueryPageableResponse } from "../../../core/type"
import { ItemPageContext } from "./ItemPageContext"
import styles from "./ItemEntry.module.scss"
import { Combo } from '../../../core/component'
import { useLazyQuery } from '@apollo/client'
import { ItemFindRequest } from '../type/item-query'
import { GetItemsForLookup } from '../graphql/item.graphql'

export const ItemFormComponents = () => {
  const { form } = useContext<PageContextValue<Item, ItemEntry>>(ItemPageContext)
  const [itemsFind] = useLazyQuery<QueryPageableResponse<Item, 'itemsFind'>, QueryPageableRequest<ItemFindRequest>>(GetItemsForLookup)

  const onSelectItem = (index: number, item: Item) => {
    form?.setFieldValue([ "relations", index, 'item', 'description' ], item?.description ?? '')
  }

  return (
    <Form.List name="relations">
      { (fields, { add, remove }) => (
        <>
          <Divider orientation="left" className="fw-bold mb-0">
            <span>{ translate("components", "/", "relations") }</span>

            <Button className="ms-3" type="default" onClick={ () => add() } icon={ <PlusOutlined/> }>
              { translate("add", "component") }
            </Button>
          </Divider>

          { fields.length ? (
            <table className={ [ "table table-borderless", styles["ComponentTable"] ].join(" ") }>
              <thead>
              <tr>
                <td className="text-center pb-0" style={ { width: "30%" } }>
                  { translate("sku") }
                </td>
                <td className="text-center pb-0" style={ { width: "60%" } }>
                  { translate("description") }
                </td>
                <td className="text-center pb-0" style={ { width: "10%" } }>
                  { translate("quantity") }
                </td>
                <td></td>
              </tr>
              </thead>

              <tbody>
              { fields.map(({ key, name }) => (
                <tr key={key}>
                  <td>
                    <Form.Item name={ [ name, 'item', 'id' ] }>
                      <Combo<Item, number>
                        size='middle'
                        fetch={async ({request, search, value})=> {
                          const {data} = await itemsFind({
                            variables: {
                              request: {
                                sku: search,
                                id: value,
                                take: request?.take ?? 10,
                                skip: request?.skip ?? 0,
                                showNoEffects: true,
                              },
                            },
                          })

                          const list = data?.itemsFind.data ?? []

                          return list.map((e) => ({
                            value: e.id || 0,
                            display: e.sku,
                            item: e || {},
                          }))
                        }}
                        onSelect={(_, item) => onSelectItem(name, item)}
                      />
                    </Form.Item>
                  </td>

                  <td>
                    <Form.Item name={ [ name, 'item', 'description' ] }>
                      <Input disabled={ true } className="input-disable-clear"/>
                    </Form.Item>
                  </td>

                  <td>
                    <Form.Item name={ [ name, 'quantity' ] }>
                      <Input
                        className="input-disable-clear"
                      />
                    </Form.Item>
                  </td>

                  <td>
                    <Form.Item noStyle name={ [ name, 'item', 'id' ] }>
                      <Commands remove={ () => remove(name) }/>
                    </Form.Item>
                  </td>
                </tr>
              )) }
              </tbody>
            </table>
          ) : null }
        </>
      ) }
    </Form.List>
  )
}

const Commands = ({ value, remove }: { value?: number, remove: () => void }) => {
  const { fetch } = useContext<PageContextValue<Item, ItemEntry>>(ItemPageContext)

  return (
    <div>
      <Space>
        { value &&
        <Tooltip title={ translate("view") }>
          <Button
            icon={ <EyeOutlined/> }
            shape="circle"
            type="text"
            onClick={ () => fetch?.({ id: value }) }
          />
        </Tooltip>
        }

        <Tooltip title={ translate('remove') }>
          <Button
            icon={ <CloseButton/> }
            shape="circle"
            type="text"
            onClick={ remove }
          />
        </Tooltip>
      </Space>
    </div>
  )
}

import { Contact } from './contact'
import { ContactRelationEntry } from './contact-relation-entry'

export enum ContactType {
  Customer = 'Customer',
  Vendor = 'Vendor',
}

export interface ContactEntry extends Omit<Contact, 'relations' | 'isCustomer' | 'isVendor'> {
  contactType?: ContactType
  relations: ContactRelationEntry[]
  isBride?: boolean
}

import { gql, TypedDocumentNode } from '@apollo/client'
import { City } from '../type'

export const GetAllCitiesAndCountriesAndStates = gql`
    query GetAllCitiesAndCountriesAndStates {
        cityList {
            name
        }

        countryList {
            name
        }
        
        statesFind(request: {take: 100, skip: 0}) {
            data {
                key
            }
        }
    }

`

export const GetCityList: TypedDocumentNode<{ cityList: City[] }> = gql`
    query GetCityList {
        cityList {
            name
        }
    }
`

import React from 'react'
import { MenuLink } from '../../../core/until'
import { translate } from '../../../core'
import { Badge, Space } from 'antd'
import { useQuery } from '@apollo/client'
import { GetNewAppointmentCountQuery } from '../graphql/appointment.graphql'
import { ShowIf } from '../../../core/component'

export const AppointmentMenuItem = () => {
  const {data} = useQuery(GetNewAppointmentCountQuery)

  return (
    <Space>
      <MenuLink
        title={translate('appointments')}
        to='/appointments'
      />
      <ShowIf
        condition={!!data?.appointmentsNewCount}
        replacement={<></>}
      >
        <Badge count={data?.appointmentsNewCount ?? 0} color='#f5222d'/>
      </ShowIf>
    </Space>
  )
}
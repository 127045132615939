import { Checkbox, DatePicker, Form, Input, Radio } from "antd"
import { translate } from "../../../core"
import { useContext } from "react"
import { ContactEntryAddresses } from "./ContactEntryAddresses"
import { ContactEntryPhones } from "./ContactEntryPhones"
import { ContactEntryWebInfos } from "./ContactEntryWebInfos"
import { PageContextValue } from "../../../core/type"
import { ContactEntry, ContactType } from "../type/contact-entry"
import { dateFormat } from "../../../core/until"
import { ContactFormGroup } from "./ContactFormGroup"
import { ContactFormCategory } from "./ContactFormCategory"
import { ContactFormRelations } from "./ContactFormRelations"
import { ContactPageContext } from "./ContactPageContext"
import { Contact } from "../type/contact"
import styles from "./ContactForm.module.scss"
import { InputCard } from "../../inventory/component/InputCard"

export const ContactForm = ({contactTypeDisabled}: { contactTypeDisabled?: boolean }) => {
  const {save, form, onFormChange} = useContext<PageContextValue<Contact, ContactEntry>>(ContactPageContext)

  return (
    <div className="px-3 pb-3">
      <Form
        onFieldsChange={() => {
          onFormChange!()
        }}
        layout="vertical"
        form={form}
        onFinish={save}
        className={styles.formRoot}
      >
        <div className={["flex w-full gap-1 items-center", styles.card].join(" ")}>
          <div className="flex-1 px-4">
            <Form.Item label={translate("relation", "group")} name={["group", "id"]}>
              <ContactFormGroup/>
            </Form.Item>
          </div>
          <div className="flex-1 px-4">
            <Form.Item
              label={translate("contact_id")}
              name="contactId"
              rules={[
                {required: true, message: translate("missing_field_message", "contract_id")},
              ]}
            >
              <Input/>
            </Form.Item>
          </div>
          <div className="flex flex-1">
            <Form.Item name="contactType">
              <Radio.Group
                disabled={contactTypeDisabled}
                className="flex flex-col"
              >
                {Object.values(ContactType).map((type) => (
                  <Radio key={type} className={["items-center", styles.radio].join(" ")} value={type}>
                    {type}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <div>
              <Form.Item className="mb-0" name="allowContact" valuePropName="checked">
                <Checkbox>{translate("allow_contact")}</Checkbox>
              </Form.Item>
              <Form.Item className="mb-0" name="noEmailMarketing" valuePropName="checked">
                <Checkbox>{translate("no", "marketing")}</Checkbox>
              </Form.Item>
            </div>
          </div>
        </div>
        <div className={styles.cardGroup}>
          <div className="flex-1">
            <InputCard title={translate("company")} className={styles.card}>
              <Form.Item label={translate("company", "name")} name="company">
                <Input/>
              </Form.Item>
              <Form.Item label={translate("designer", "tag")} name="designer">
                <Input/>
              </Form.Item>
              <Form.Item label={translate("category")} name={["category", "id"]}>
                <ContactFormCategory/>
              </Form.Item>
              <Form.Item label={translate("account")} name="accountNumber">
                <Input/>
              </Form.Item>
              <Form.Item label={translate("markup", "number_abbr")} name="markupNumber">
                <Input/>
              </Form.Item>
              <Form.Item label={translate("percent_off")} name="percentOff">
                <Input/>
              </Form.Item>
              <Form.Item label={translate("promotion")} name="promotion">
                <Input style={{width: "100"}}/>
              </Form.Item>
            </InputCard>
          </div>
          <div className="flex-1">
            <InputCard title={translate("contact")} className={styles.card}>
              <Form.Item label={translate("first_name")} name="firstName">
                <Input/>
              </Form.Item>
              <Form.Item label={translate("last_name")} name="lastName">
                <Input/>
              </Form.Item>
              <Form.Item label={translate("date_of_birth")} name="dateOfBirth">
                <DatePicker format={dateFormat} style={{width: "100%"}}/>
              </Form.Item>
              <Form.Item label={translate("anniversary")} name="anniversary">
                <DatePicker format={dateFormat} style={{width: "100%"}}/>
              </Form.Item>
            </InputCard>
            <Form.Item label={translate("notes")} name="note" className="mb-0">
              <Input.TextArea rows={3}/>
            </Form.Item>
          </div>
        </div>

        <ContactFormRelations/>

        <ContactEntryAddresses/>

        <ContactEntryPhones/>

        <ContactEntryWebInfos/>
      </Form>
    </div>
  )
}

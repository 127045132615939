import { XIcon } from './XImage'
import DashboardSvg from '../../assets/dashboard.svg'
import AppraisalSvg from '../../assets/appraisal.svg'
import InventorySvg from '../../assets/inventory.svg'
import CustomerSvg from '../../assets/contact.svg'
import VendorSvg from '../../assets/vendor.svg'
import OrderSvg from '../../assets/order.svg'
import LogoutSvg from '../../assets/logout.svg'
import GoogleSvg from '../../assets/google.svg'
import BellSvg from '../../assets/bell.svg'
import EnvelopSvg from '../../assets/envelop.svg'
import UploadCloudSvg from '../../assets/upload-cloud.svg'
import ImageSvg from '../../assets/image.svg'
import PdfSvg from '../../assets/pdf.svg'
import UserSvg from '../../assets/user.svg'
import WeddingSvg from '../../assets/wedding.svg'
import WarehouseSvg from '../../assets/warehouse.svg'
import OnlineSvg from '../../assets/online.svg'
import BuySvg from '../../assets/buy.svg'
import { IconProps } from '../../core/type'

export const DashboardIcon = (props: IconProps) => <XIcon src={ DashboardSvg } alt='dashboard' { ...props }/>
export const AppraisalIcon = (props: IconProps) => <XIcon src={ AppraisalSvg } alt='appraisal' { ...props }/>
export const InventoryIcon = (props: IconProps) => <XIcon src={ InventorySvg } alt='inventory' { ...props }/>
export const ContactIcon = (props: IconProps) => <XIcon src={ CustomerSvg } alt='customer' { ...props }/>
export const VendorIcon = (props: IconProps) => <XIcon src={ VendorSvg } alt='vendor' { ...props }/>
export const OrderIcon = (props: IconProps) => <XIcon src={ OrderSvg } alt='order' { ...props }/>
export const LogoutIcon = (props: IconProps) => <XIcon src={ LogoutSvg } alt='logout' { ...props }/>
export const GoogleIcon = (props: IconProps) => <XIcon src={ GoogleSvg } alt='google' { ...props }/>
export const BellIcon = (props: IconProps) => <XIcon src={ BellSvg } alt='google' { ...props }/>
export const EnvelopIcon = (props: IconProps) => <XIcon src={ EnvelopSvg } alt='envelop' { ...props }/>
export const UploadCloudIcon = (props: IconProps) => <XIcon src={ UploadCloudSvg } alt='upload-cloud' { ...props }/>
export const ImageIcon = (props: IconProps) => <XIcon src={ ImageSvg } alt='image' { ...props }/>
export const PdfIcon = (props: IconProps) => <XIcon src={ PdfSvg } alt='pdf' { ...props }/>
export const UserIcon = (props: IconProps) => <XIcon src={ UserSvg } alt='pdf' { ...props }/>
export const WeddingIcon = (props: IconProps) => <XIcon src={ WeddingSvg } alt='wedding' { ...props }/>
export const WarehouseIcon = (props: IconProps) => <XIcon src={ WarehouseSvg } alt='warehosue' { ...props }/>
export const OnlineIcon = (props: IconProps) => <XIcon src={OnlineSvg} alt='online' { ...props }/>
export const BuyIcon = (props: IconProps) => <XIcon src={BuySvg} alt='buy' { ...props }/>


